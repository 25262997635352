import { Component, Input, OnInit } from '@angular/core';
import { LoggedUserName } from 'src/app/core/api/services/auth-service/auth.service';
import { DicesThrowResults } from 'src/app/core/api/services/dice-service/dice.service';
import { GameFeedRecord } from 'src/be-models/interfaces/game-feed/game-feed.interface';
import { ColumnDefinitions, RowData } from '../table/models';

interface TableToRender {
  col: ColumnDefinitions;
  data: any[];
}

@Component({
  selector: 'app-dices-results',
  templateUrl: './dices-results.component.html',
  styleUrls: ['./dices-results.component.scss'],
})
export class DicesResultsComponent implements OnInit {
  tablesToRender: TableToRender[] = [];
  tablesWithMetadata: { tableToRender: TableToRender; diceType: string }[] = [];

  constructor() {}

  @Input() set results(results: DicesThrowResults[]) {
    this.gameFeedRecord = {
      roll: {
        dicesThrowsResults: results,
      },
      characterName: 'Me',
      // userId: UserDetails.id,
    };
    this.prepareTablesToRender(results);
  }

  gameFeedRecord: GameFeedRecord = undefined;

  ngOnInit() {}

  prepareTablesToRender(results: DicesThrowResults[]) {
    this.tablesWithMetadata = [];
    for (const result of results) {
      const rowsToRender = this.getRowsForTable(result);
      const columnsDefiniton = this.getColumnDefinitionsForTable(result);
      this.tablesWithMetadata.push({
        tableToRender: { col: columnsDefiniton, data: rowsToRender },
        diceType: result.diceType.toString(),
      });
    }
  }

  private getColumnDefinitionsForTable(
    result: DicesThrowResults
  ): ColumnDefinitions {
    const throwColumnDefinition = {
      throw: { id: 'throw', title: 'Throw', width: 50, optional: true },
    };
    if (result.dicesThrows[0]) {
      return Object.assign(
        throwColumnDefinition,
        result.dicesThrows[0]?.diceResults.reduce(
          (prev, curr) => ({
            ...prev,
            ['d' + curr.diceId.toString()]: {
              id: 'd' + curr.diceId,
              title: `D${curr.diceId}`,
              width: 30,
            },
          }),
          {}
        )
      );
    }
    return {};
  }

  private getRowsForTable(result: DicesThrowResults): RowData[] {
    const rowsToRender = [];
    for (const singleThrow of result.dicesThrows) {
      const rowToRender = { throw: singleThrow.throwId };
      rowsToRender.push(
        Object.assign(
          rowToRender,
          singleThrow.diceResults.reduce(
            (prev, curr) => ({ ...prev, ['d' + curr.diceId]: curr.value }),
            {}
          )
        )
      );
    }
    return rowsToRender;
  }
}

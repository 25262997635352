import { AbilityPopulated } from "../abilities/ability.interface";
import ReductionStats from "../item-definitions/models/armor-reduction-stats";
import ArmorStats from "../item-definitions/models/armor-stats";
import { Modify } from "../utils";
import { BaseCreature } from "./base-creature";
import { MeleeWeaponBaseType } from "../item-definitions/types/melee-weapon-type.interface";
import {
  MonsterDefinition,
  MonsterDefinitionPopulated,
} from "../monster-definitions/monster-definition";
import Condition from "../conditions/condition";
import { Skill } from "../../enums/skills.const";

export interface CustomMonsterAbility {
  id?: string;
  title: string;
  description: string;
}

export class MonsterWeaponClass {
  name = "";
  toHit = "";
  //
  reach = "";
  meleeType = "";
  damageType = "";
  //
  damage = "";
  critDamage = "";
  ap = "";
  //
  power = "";
  quickness = "";
  precision = "";
}

export interface MonsterWeapon extends MonsterWeaponClass {}

export const monsterPropertiesKeys = Object.keys(
  new MonsterWeaponClass()
) as (keyof MonsterWeapon)[];

export class MonsterDIMRASClass {
  dodge = "";
  initiative = "";
  movementSpeed = "";
  reactions = "";
  actions = "";
  sizeBonus = "";
}

export interface MonsterDIMRAS extends MonsterDIMRASClass {}

export const monsterDimrasPropertiesKeys = Object.keys(
  new MonsterDIMRASClass()
) as (keyof MonsterDIMRAS)[];

export interface Monster extends BaseCreature {
  currentHp: number;
  maxHp: number;
  baseHp?: number;

  weapon1: MonsterWeapon;
  weapon2: MonsterWeapon;
  weapon3: MonsterWeapon;

  armorStats: ArmorStats;
  reductionStats: ReductionStats;

  baseArmorStats?: ArmorStats;
  baseReductionStats?: ReductionStats;

  dimras: MonsterDIMRAS;
  baseDimras?: MonsterDIMRAS;

  armorDurability: string;
  armorMaxDurability: string;
  baseArmorDurability?: string;

  skills: typeof Skill;
  baseSkills?: typeof Skill;

  description: string;
  groupId: string;
  preset?: string;
  customAbilities: CustomMonsterAbility[];
}

export interface MonsterPopulated
  extends Modify<
    Monster,
    {
      abilities: AbilityPopulated[];
      activeConditions: { condition: Condition; amountOfStacks?: number }[];
    }
  > {}

export interface MonsterPopulatedWithDefinition
  extends Modify<
    Monster,
    {
      abilities: AbilityPopulated[];
      preset?: MonsterDefinitionPopulated;
      activeConditions: { condition: Condition; amountOfStacks?: number }[];
    }
  > {}

import { Types } from "mongoose";
import User from "../auth/user.interface";
import { GameFeed } from "../game-feed/game-feed.interface";
import { Modify } from "../utils";
import { PlayerSlot, PlayerSlotPopulatedRegistry } from "./player-slot";
import { AnomalySecret } from "./anomaly-secret";
import { AnomalyDicePoolEffect } from "../anomaly-dice-pool-effect/anomaly-dice-pool-effect.interface";

export interface Game {
  id: string;
  title: string;
  dateCreated: number;
  creator: string;
  invitedUsers: { id: string; displayName: string }[];
  isDeleted: boolean;
  invitationLink: string;
  invitationLinkStatus: boolean;
  playerSlots: PlayerSlot[];
  dateModified: number;
  staticSettings: GameStaticSettings;
  anomalySecret?: AnomalySecret;

  // TODO: cleanup unused data from the model
  // To be moved to ingame details;
  // inGameDate: number;
  // gameFeed: Types.ObjectId;
  // settings: { [key: string]: any };
  // npcGroups: { [key: string]: string[] };
  // anomalyLootPoolNumber: number;
  // anomalyCurrentEffect?: AnomalyDicePoolEffect;
}

export interface GamePopulated
  extends Modify<
    Game,
    {
      playerSlots: PlayerSlot[];
      invitedUsers: { displayName: string }[];
      gameFeed: GameFeed;
    }
  > {}

export interface GameListResponseRecord {
  id: string;
  title: string;
  dateCreated: number;
  dateModified: number;
  role: GameRole;
  // TODO: expand on this
  // allowedActions: any,
  invitationLinkEnabled: boolean;
  invitationLink: string;
  staticSettings: GameStaticSettings;
  // isOnline: boolean;
}

export interface GamePopulatedRegistry
  extends Modify<
    GamePopulated,
    {
      playerSlots: PlayerSlotPopulatedRegistry[];
      creator?: string;
    }
  > {
  role: GameRole;
  // slotDetails: Partial<PlayerSlotPopulated>;
}

export enum GameRole {
  Player,
  GM,
}

export interface GameStaticSettings {
  startingCharacterLevel: number;
  maxBackgroundTier: number;
  pointsForStartingEquipment: number;
  traitPointsModifier: number;
}

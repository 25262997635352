import { SkillsUtils } from 'src/app/utils/skills-utils';
import Ability, {
  AbilityPopulated,
} from 'src/be-models/interfaces/abilities/ability.interface';
import { BackgroundPopulated } from 'src/be-models/interfaces/backgrounds/background.interface';
import {
  BaseCreature,
  CreaturePopulated,
} from 'src/be-models/interfaces/characters/base-creature';
import {
  Character,
  CharacterPopulated,
} from 'src/be-models/interfaces/characters/character';
import { CreatureType } from 'src/be-models/interfaces/characters/creature-type.enum';
import {
  Monster,
  MonsterPopulated,
  MonsterPopulatedWithDefinition,
} from 'src/be-models/interfaces/characters/monster';
import { NPCPopulated, NPC } from 'src/be-models/interfaces/characters/npc';
import Condition from 'src/be-models/interfaces/conditions/condition';
import { FoodCategory } from 'src/be-models/interfaces/item-definitions/enums/food-category.enum';
import ConsumableItemType from 'src/be-models/interfaces/item-definitions/types/consumable-type.interface';
import { MonsterDefinitionPopulated } from 'src/be-models/interfaces/monster-definitions/monster-definition';
import { TraitPopulated } from 'src/be-models/interfaces/traits/trait.interface';
import { MonsterDefinitionsStoreService } from '../../api/stores/monster-definitions.store';
import {
  NewManualPropertyValuePair,
  CharDetailsConditionsUpdatedAction,
  WritableStatCategory,
  CharDetailsActionExternalPayload,
  ConditionIdWithAmount,
} from './game-character-actions';

// TODO: move to better file
export interface AvailableAbilityPoints {
  any: number;
  combat: number;
  nonCombat: number;
  anomaly: number;
}

export const nutritionRelatedConditions = [
  'Good_nutrition',
  'Rationing',
  'Starvation',
  'Near_death_starvation',
];

export const hydrationRelatedConditions = [
  'Well_hydrated',
  'Dehydration',
  'Severe_dehydration',
  'Near_death_dehydration',
];

export const dietCategoryRelatedConditions = [
  'Diverse_diet',
  'Balanced_diet',
  'Lacking_diet_1',
  'Lacking_diet_2',
  'Lacking_diet_3',
];

export const singleDietCategoryRelatedConditions = [
  'Food_bonus_type_A',
  'Food_bonus_type_B',
  'Food_bonus_type_C',
  'Food_bonus_type_D',
  'Food_bonus_type_E',
  'Food_bonus_type_F',
  'Food_bonus_type_G',
];

export class CharactersUtils {
  // static failedAbilitiesIds: string[] = [];
  static populateCreature(
    creature: BaseCreature,
    allAbilities: AbilityPopulated[],
    allConditions: Condition[],
    allMonsterDefinitions: MonsterDefinitionPopulated[],
    allBackgrounds: BackgroundPopulated[],
    allTraits: TraitPopulated[]
  ): CreaturePopulated {
    if (CharactersUtils.isMonsterCharacterUnpopulated(creature)) {
      return CharactersUtils.enhanceMonstersWithDefinitionData(
        CharactersUtils.populateNpcMonsterCharacter(
          creature,
          allAbilities,
          allConditions
        ),
        allMonsterDefinitions
      );
    } else if (CharactersUtils.isNPCCharacterUnpopulated(creature)) {
      return CharactersUtils.populateNpcMonsterCharacter(
        creature,
        allAbilities,
        allConditions
      );
    } else if (CharactersUtils.isPlayerCharacterUnpopulated(creature)) {
      return CharactersUtils.populatePlayerCharacter(
        creature,
        allBackgrounds,
        allTraits,
        allAbilities,
        allConditions
      );
    }
    return undefined;
  }
  static depopulatePlayerCharacter(input: CharacterPopulated): Character {
    return {
      ...input,
      abilities: input.abilities.map((x) => x.id),
      background: input.background.id,
      activeConditions: input.activeConditions.map((x) => ({
        condition: x.condition.id,
        amountOfStacks: x.amountOfStacks,
      })),
      traits: input.traits.map((x) => x.id),
    };
  }

  static depopulateNpcCharacter(input: NPCPopulated): NPC {
    return {
      ...input,
      abilities: input.abilities.map((x) => x.id),
      activeConditions: input.activeConditions.map((x) => ({
        condition: x.condition.id,
        amountOfStacks: x.amountOfStacks,
      })),
    };
  }

  // T extends string | number, R = T extends string ? string : number

  static populateNpcMonsterCharacter<T extends NPC | Monster>(
    input: T,
    allAbilities: AbilityPopulated[],
    allConditions: Condition[]
  ): T extends NPC ? NPCPopulated : MonsterPopulated {
    const populatedEntity = {
      ...input,
      abilities: input.abilities.map((x) =>
        allAbilities.find((y) => y.id === x)
      ),
      activeConditions: input.activeConditions.map((x) => ({
        ...x,
        condition: allConditions.find((y) => y.id === x.condition),
      })),
    };

    if (populatedEntity.abilities.filter((x) => x == null)?.length > 0) {
      // CharactersUtils.failedAbilitiesIds.push(
      //   ...input.abilities
      //     .map((v, i, a) => {
      //       if (populatedEntity.abilities[i] == null) {
      //         return v;
      //       }
      //     })
      //     .filter((x) => x)
      // );
      console.error(
        'ability not populated for char: ',
        input,
        'for abilities:',
        input.abilities
          .map((v, i, a) => {
            if (populatedEntity.abilities[i] == null) {
              return v;
            }
          })
          .filter((x) => x)
      );

      // setInterval(() => console.log(CharactersUtils.failedAbilitiesIds), 10000);
    }

    return populatedEntity as never as T extends NPC
      ? NPCPopulated
      : MonsterPopulated;
  }

  static populatePlayerCharacter(
    character: Character,
    allBackgrounds: BackgroundPopulated[],
    allTraits: TraitPopulated[],
    allAbilities: AbilityPopulated[],
    allConditions: Condition[]
  ): CharacterPopulated {
    return {
      ...character,
      background: allBackgrounds.find((x) => x.id === character.background)!,
      traits: character.traits.map((x) => allTraits.find((y) => y.id === x)!),
      abilities: character.abilities.map(
        (x) => allAbilities.find((y) => y.id === x)!
      ),
      activeConditions: character.activeConditions.map((x) => ({
        ...x,
        condition: allConditions.find((y) => y.id === x.condition)!,
      })),
    };
  }

  static enhanceMonstersWithDefinitionData(
    monster: MonsterPopulated,
    allMonsterDefinitions: MonsterDefinitionPopulated[]
  ): MonsterPopulatedWithDefinition {
    return {
      ...monster,
      preset: allMonsterDefinitions.find((x) => x.id === monster.preset),
    };
  }

  static resolveFoodRelatedConditionsActions(
    charDetails: CharacterPopulated,
    eatenFoodItemStats: Partial<ConsumableItemType> = {
      nutrition: 0,
      hydration: 0,
      foodCategory: [],
    }
  ): CharDetailsConditionsUpdatedAction | undefined {
    let newConditionsIdsArray: string[] = [];
    let conditionsIdsToRemoveArray: string[] = [];

    //  id: "Well_hydrated"
    //  id: "Good_nutrition"
    //  Jak jest 12 jest well fed/good nutrition.
    //  - Jeśli ktoś ma deficyt, nie może być “well fed” następnego dnia.
    if (
      charDetails.dietInfo.history?.[0].hydrationAcc +
        eatenFoodItemStats.hydration >=
        12 &&
      !charDetails.activeConditions.some(
        (x) => x.condition.id === 'Well_hydrated'
      ) &&
      charDetails.dietInfo.hydrationDeficit >= 0
    ) {
      newConditionsIdsArray.push('Well_hydrated');
    }

    if (
      charDetails.dietInfo.history?.[0].nutritionAcc +
        eatenFoodItemStats.nutrition >=
        12 &&
      !charDetails.activeConditions.some(
        (x) => x.condition.id === 'Good_nutrition'
      ) &&
      charDetails.dietInfo.nutritionDeficit >= 0
    ) {
      newConditionsIdsArray.push('Good_nutrition');
    }

    // DIET condition is applied right after eating and lasts as long as sum of 3 days (INCLUDING today) meets the condition.
    // ergo: morning of new day is when player can LOSE diet bonus/condition, worst time to be alive.

    //     ++	Diverse diet	Characters Brawn and Persona are increased by 1. Base injury recovery time is decreased by 2 days.
    // +	Balanced diet	Characters Brawn is increased by 1. Base injury recovery time is decreased by 1 day.
    // 0	Normal diet	No effects.
    // -1	Lacking diet 1	Character receives a -2 penalty to Disease resistance tests.
    // -2	Lacking diet 2	Dicepool used for HP regeneration on long rests is reduced by half of the characters level (rounded up). Character also receives a -2 penalty to Disease resistance tests.
    // -3	Lacking diet 3	Dicepool used for HP regeneration on long rests is reduced by characters level. Character also receives a -4 penalty to Disease resistance tests.

    // id: "Diverse_diet"
    // id: "Balanced_diet"
    // id: "Lacking_diet_1"
    // id: "Lacking_diet_2"
    // id: "Lacking_diet_3"

    const accumulatedDietCategories = charDetails.dietInfo.history
      .reduce((prev, curr) => {
        return [...prev, ...curr.dietCategoriesAcc];
      }, [] as FoodCategory[])
      .filter((x, i, a) => a.indexOf(x) === i);

    const newFoodCategories = eatenFoodItemStats.foodCategory.filter(
      (x) => !accumulatedDietCategories.includes(x)
    );
    // if (newFoodCategories.length > 0) {
    accumulatedDietCategories.push(...newFoodCategories);
    //         const foodRelatedConditionsEligible = allConditions.filter(
    //           (condition) =>
    //             activeFoodBonusTypes
    //               .map((x) => 'Food_bonus_type_' + x)
    //               .includes(condition.id)
    //         );
    newConditionsIdsArray.push(
      ...accumulatedDietCategories.map((x) => 'Food_bonus_type_' + x)
    );

    if (accumulatedDietCategories.length === 0) {
      newConditionsIdsArray.push('Lacking_diet_3');
    } else if (accumulatedDietCategories.length === 1) {
      newConditionsIdsArray.push('Lacking_diet_2');
    } else if (accumulatedDietCategories.length === 2) {
      newConditionsIdsArray.push('Lacking_diet_1');
    } else if (
      accumulatedDietCategories.length === 5 ||
      accumulatedDietCategories.length === 6
    ) {
      newConditionsIdsArray.push('Balanced_diet');
    } else if (accumulatedDietCategories.length === 7) {
      newConditionsIdsArray.push('Diverse_diet');
    }

    // removal of old conditions
    conditionsIdsToRemoveArray.push(
      ...charDetails.activeConditions
        .map((x) => x.condition.id)
        .filter(
          (x) =>
            [
              ...dietCategoryRelatedConditions,
              ...singleDietCategoryRelatedConditions,
              // .filter(
              //   (y) =>
              //     !accumulatedDietCategories
              //       .map((x) => 'Food_bonus_type_' + x)
              //       .includes(y)
              // )
              ...hydrationRelatedConditions,
              ...nutritionRelatedConditions,
            ].includes(x) && !newConditionsIdsArray.includes(x)
        )
    );

    // dont add those that are already there
    newConditionsIdsArray = newConditionsIdsArray.filter(
      (x) =>
        !charDetails.activeConditions.map((y) => y.condition.id).includes(x)
    );

    if (
      newConditionsIdsArray.length === 0 &&
      conditionsIdsToRemoveArray.length === 0
    ) {
      return undefined;
    }
    return new CharDetailsConditionsUpdatedAction(
      new CharDetailsActionExternalPayload(charDetails),
      conditionsIdsToRemoveArray.map<ConditionIdWithAmount>((x) => ({ id: x })),
      newConditionsIdsArray.map<ConditionIdWithAmount>((x) => ({ id: x }))
    );
  }

  static resolveModifierAttributePropertyValuePair(x: {
    statCategory: WritableStatCategory;
    propertyId: string;
    newValue: any;
  }): NewManualPropertyValuePair {
    let propertyPath = '';
    switch (x.statCategory) {
      case WritableStatCategory.Attributes:
        propertyPath = 'currentAttributes.' + x.propertyId;
        break;
      case WritableStatCategory.ResistanceStats:
        propertyPath = 'modifiers.reduction.' + x.propertyId;
        break;
      case WritableStatCategory.ArmorStat:
        propertyPath = 'modifiers.armor.' + x.propertyId;
        break;
      case WritableStatCategory.AttackBonus:
        propertyPath = 'modifiers.attackBonuses.' + x.propertyId;
        break;
      case WritableStatCategory.DIMD:
        propertyPath = 'modifiers.dimd.' + x.propertyId;
        break;
      case WritableStatCategory.Skill:
        propertyPath = 'modifiers.skill.' + x.propertyId;
        break;
      case WritableStatCategory.TrackCurrent:
        propertyPath =
          (x.propertyId.includes('hp')
            ? 'currentHitPoints.'
            : 'currentSanityPoints.') +
          x.propertyId.split(/hp|sp/)[1].toLowerCase();
        break;
      case WritableStatCategory.TrackMax:
        propertyPath =
          (x.propertyId.includes('hp') ? 'maxHitPoints.' : 'maxSanityPoints.') +
          x.propertyId.split(/hp|sp/)[1].toLowerCase();
        break;
      case WritableStatCategory.ToHitBonus:
        propertyPath = 'modifiers.toHitBonus';
        break;
    }

    return { propertyPath, value: x.newValue };
  }

  static isPlayerCharacter(
    creature: CreaturePopulated
  ): creature is CharacterPopulated {
    return creature.type === CreatureType.Player;
  }

  static isNPCCharacter(creature: CreaturePopulated): creature is NPCPopulated {
    return creature.type === CreatureType.NPC;
  }

  static isMonsterCharacter(
    creature: CreaturePopulated
  ): creature is MonsterPopulatedWithDefinition {
    return creature.type === CreatureType.Monster;
  }

  static isPlayerCharacterUnpopulated(
    creature: BaseCreature
  ): creature is Character {
    return creature.type === CreatureType.Player;
  }

  static isNPCCharacterUnpopulated(creature: BaseCreature): creature is NPC {
    return creature.type === CreatureType.NPC;
  }

  static isMonsterCharacterUnpopulated(
    creature: BaseCreature
  ): creature is Monster {
    return creature.type === CreatureType.Monster;
  }

  static resolveCreatureJointAbilities(
    creature: CreaturePopulated
  ): (Ability | AbilityPopulated)[] {
    return [
      ...(CharactersUtils.isPlayerCharacter(creature)
        ? creature.background?.abilities ?? []
        : []),
      ...(CharactersUtils.isPlayerCharacter(creature)
        ? creature.traits?.reduce<Ability[]>(
            (prev, curr) => [...prev, ...(curr.abilities ?? [])],
            []
          ) ?? []
        : []),
      ...creature.abilities,
    ];
  }

  static calculateHpNormal(
    char: CreaturePopulated,
    jointAbilities: (AbilityPopulated | Ability)[]
  ) {
    const isPlayer = CharactersUtils.isPlayerCharacter(char);
    return (
      5 +
      // if character has "Tough", multiply level times 2
      char.level *
        (isPlayer && char.traits.some((x) => x.id === 'Tough') ? 2 : 1) +
      (char.currentAttributes?.brawn ?? 0) * 2 +
      SkillsUtils.resolveStatsBonus('HP', jointAbilities)
    );
  }

  static calculateHpInjuried(char: CreaturePopulated) {
    return char.currentAttributes.persona * 2;
  }

  static calculateHpDying(char: CreaturePopulated) {
    return 5 + char.currentAttributes.brawn;
  }

  static calculateSpNormal(
    char: CreaturePopulated,
    jointAbilities: (AbilityPopulated | Ability)[]
  ) {
    const isPlayer = CharactersUtils.isPlayerCharacter(char);
    return (
      5 +
      // if character has "Determined", multiply level times 2
      char.level *
        (isPlayer && char.traits.some((x) => x.id === 'Determined') ? 2 : 1) +
      char.currentAttributes.persona * 2 +
      SkillsUtils.resolveStatsBonus('SP', jointAbilities)
    );
  }

  static calculateSpScarred(char: CreaturePopulated) {
    return char.currentAttributes.persona * 2;
  }

  static calculateSpCatatonic(char: CreaturePopulated) {
    return 5 + char.currentAttributes.persona;
  }

  static calculateNpcCharSkill(
    npc: CharacterPopulated | NPCPopulated,
    skillName: string
  ): number {
    const jointAbilities = CharactersUtils.resolveCreatureJointAbilities(npc);

    return SkillsUtils.resolveStatsBonus(skillName, jointAbilities);
  }
}

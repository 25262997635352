export const environment = {
  production: false,
  MAX_ENTITIES_AMOUNT: 1000,
  homepageUrl: 'https://www.2minutespastmidnight.com',
  // baseUrl: 'https://2mtm-api.azurewebsites.net',
  baseUrl: 'https://stagingbe-fnbearh8gkawdpac.westeurope-01.azurewebsites.net',
  // TODO: revert to localhost:3000
  msalSuccessLoginRedirectUrl: 'https://staging.2minutespastmidnight.com',
  msalSuccessLogoutRedirectUrl: 'https://staging.2minutespastmidnight.com',
  msalFailureLoginRedirectUrl: 'https://staging.2minutespastmidnight.com',
  msalConfig: {
    auth: {
      clientId: '6b19be5d-58d5-4486-8f30-3f57ce1e2cdf',
    },
  },
  // apiConfig: {
  //   scopes: [
  //     'https://2mpmEurope.onmicrosoft.com/api/Api.Read',
  //     'https://2mpmEurope.onmicrosoft.com/api/Api.Write',
  //   ],
  //   uri: 'https://2mpmEurope.onmicrosoft.com/api/*',
  //   // uri: 'https://fabrikamb2chello.azurewebsites.net/hello',
  // },
  apiConfig: {
    uri: 'https://stagingbe-fnbearh8gkawdpac.westeurope-01.azurewebsites.net',
    scopes: [
      'https://2mpmEurope.onmicrosoft.com/api/Api.Write',
      'https://2mpmEurope.onmicrosoft.com/api/Api.Read',
    ],
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1_Signupsignin_Europe',
      resetPassword: 'B2C_1_pass_reset_Europe',
      editProfile: 'B2C_1_editUser_Europe',
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://2mpmEurope.b2clogin.com/2mpmEurope.onmicrosoft.com/B2C_1_Signupsignin_Europe',
      },
      resetPassword: {
        authority:
          'https://2mpmEurope.b2clogin.com/2mpmEurope.onmicrosoft.com/B2C_1_pass_reset_Europe',
      },
      editProfile: {
        authority:
          'https://2mpmEurope.b2clogin.com/2mpmEurope.onmicrosoft.com/B2C_1_editUser_Europe',
      },
    },
    authorityDomain: 'https://2mpmEurope.b2clogin.com',
  },
};

import { Component, Injector } from '@angular/core';

import { AuthService } from './core/api/services/auth-service/auth.service';
import { AppService } from './core/services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private appService: AppService,
    private injector: Injector,
    private authService: AuthService
  ) {
    this.appService.appInjector = this.injector;
  }

  async ngOnInit(): Promise<void> {
    this.authService.init();
  }
}

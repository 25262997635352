import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SingleDiceResult } from 'src/be-models/interfaces/dice-roll';
import { GameFeedRecord } from 'src/be-models/interfaces/game-feed/game-feed.interface';

@Component({
  selector: 'app-feed-record',
  templateUrl: './feed-record.component.html',
  styleUrls: ['./feed-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedRecordComponent implements OnInit {
  @Input() gameFeedRecord: GameFeedRecord;

  cache: Map<string, Map<string, number>> = new Map<
    string,
    Map<string, number>
  >();

  constructor() {}

  ngOnInit() {}

  public sortDices(dicesResults: SingleDiceResult[]) {
    return dicesResults.map((x) => x.value).sort((a, b) => (a > b ? -1 : 1));
  }

  public sumUpDiceResults(dicesResults: SingleDiceResult[], diceType: number) {
    const cachedResult = this.cache.get(
      JSON.stringify(dicesResults) + diceType
    );
    if (cachedResult) {
      return cachedResult;
    } else {
      const dicesResultsGroupedMap = new Map<string, number>();
      for (let index = 1; index <= diceType; index++) {
        dicesResultsGroupedMap.set(index + "'s", 0);
      }
      const groupedUpMap = this.sortDices(dicesResults)
        .reverse()
        .reduce<Map<string, number>>((acc, curr) => {
          acc.set(curr + "'s", (acc.get(curr + "'s") ?? 0) + 1);
          return acc;
        }, new Map());
      // new Map(Array.from(origMap, a => a.reverse()))

      const finalMap = new Map([...dicesResultsGroupedMap, ...groupedUpMap]);
      this.cache.set(
        JSON.stringify(dicesResults) + diceType,
        new Map(Array.from(finalMap.entries()).reverse())
      );
      return new Map(Array.from(finalMap.entries()).reverse());
    }
  }
}

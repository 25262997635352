import Ability from "../abilities/ability.interface";
import StatsBonus from "../shared/stats-bonus.interface";
import { Modify } from "../utils";

export default interface BaseTrait {
  id: string;
  name: string;
  cost: number;
  description: string;
  abilities: string[];
  effects?: StatsBonus | string;
  special?: boolean;
}

export interface TraitPopulated
  extends Modify<BaseTrait, { abilities: Ability[] }> {}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Background, {
  BackgroundPopulated,
} from 'src/be-models/interfaces/backgrounds/background.interface';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root',
})
export class BackgroundsService {
  constructor(private http: HttpService) {}
  getAllBackgrounds(): Observable<Background[]> {
    return this.http.get('/backgrounds?version=5');
  }

  getBackgroundsById(id: string): Observable<Background[]> {
    return this.http.get('/backgrounds/' + id);
  }
}

import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export default class GlobalUtils {
  // Just for fun, to explore overloads. If anything happens, use commented separated methods at the bottom of file
  static groupBy<K, V>(
    list: Array<V>,
    keyGetter: (input: V) => K
  ): Map<K, Array<V>>;
  static groupBy<K, V, Y = any>(
    list: Array<V>,
    keyGetter: (input: V) => K,
    valueToSaveFunc: (input: V) => Y
  ): Map<K, Array<Y>>;
  static groupBy<K, V, Y = any>(
    list: Array<V>,
    keyGetter: (input: V) => K,
    valueToSaveFunc: (input: V) => Y = undefined
  ): Map<K, Array<Y>> | Map<K, Array<V>> {
    const map = valueToSaveFunc
      ? new Map<K, Array<Y>>()
      : new Map<K, Array<V>>();
    if (GlobalUtils.isStraightType<K, V, Y>(map, valueToSaveFunc)) {
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });

      return map;
    } else {
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [valueToSaveFunc(item)]);
        } else {
          collection.push(valueToSaveFunc(item));
        }
      });

      return map;
    }
  }

  static isStraightType<K, V, Y>(
    map: Map<K, Array<V>> | Map<K, Array<Y>>,
    valueToSaveFunc
  ): map is Map<K, Array<V>> {
    return valueToSaveFunc === undefined;
  }

  static isObjectEmpty(obj): boolean {
    for (const i in obj) return false;
    return true;
  }

  static ensureArrayType<T = any>(obj: T | Array<T>): Array<T> {
    if (obj === undefined) {
      return [];
    }
    if (obj === null) {
      return [];
    }
    if (obj instanceof Array) {
      return obj;
    } else {
      return [obj];
    }
  }

  static arraysEqual(a, b, ordered = false) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
    let aPrime = a;
    let bPrime = b;
    if (!ordered) {
      aPrime = [...a];
      bPrime = [...b];
      aPrime.sort();
      bPrime.sort();
    }

    for (let i = 0; i < aPrime.length; ++i) {
      if (aPrime[i] !== bPrime[i]) return false;
    }
    return true;
  }

  static findInSet(pred, set) {
    for (const item of set) {
      if (pred(item)) {
        return item;
      }
    }
  }

  static rollInRange(min: number, max: number): number {
    return Math.round(Math.random() * (max - min) + min);
  }

  static lowerKeysOfObject(obj) {
    if (obj == null) {
      return;
    }
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  static integerValidator = Validators.pattern(/^[-]?\d+$/);
  static positiveIntegerValidator = Validators.pattern(/^[0-9]*[1-9][0-9]*$/);
  static positiveIntegerWithZeroValidator = Validators.pattern(/^[0-9]*$/);

  static createNumberRangeValidator(
    minRange: number,
    maxRange: number,
    allowFractions = false
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const isEqualOrHigherThanMin = value >= minRange;
      const isEqualOrLowerThanMax = value <= maxRange;
      const fractionsCondition = allowFractions
        ? true
        : Math.round(value) === value;
      const rangeValid =
        isEqualOrHigherThanMin && isEqualOrLowerThanMax && fractionsCondition;

      return !rangeValid ? { rangeValidity: true } : null;
    };
  }

  static isJson(item) {
    let value = typeof item !== 'string' ? JSON.stringify(item) : item;
    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }

    return true;
  }

  static setDeepValue(obj: any, path: string, value: any): void {
    const keys = path.split('.');
    let current = obj;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // If it's the last key in the path, set the value
      if (i === keys.length - 1) {
        current[key] = value;
      } else {
        // If the current key doesn't exist or is not an object, create an empty object
        if (!current[key] || typeof current[key] !== 'object') {
          current[key] = {};
        }

        // Move to the next level
        current = current[key];
      }
    }
  }

  static newObjectId() {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const objectId =
      timestamp +
      'xxxxxxxxxxxxxxxx'
        .replace(/[x]/g, () => {
          return Math.floor(Math.random() * 16).toString(16);
        })
        .toLowerCase();

    return objectId;
  }

  static isFirefox() {
    return navigator.userAgent.toLowerCase().includes('firefox');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipPopoverComponent } from './tooltip-popover.component';



@NgModule({
  declarations: [TooltipPopoverComponent],
  imports: [
    CommonModule
  ],
  exports: [TooltipPopoverComponent]
})
export class TooltipPopoverModule { }

import { environment } from '../../../../environments/environment';

export default class HttpUtils {
  static getApiUrl(): string {
    return !HttpUtils.isLocalhost() ? environment.baseUrl + '/api' : '/api';
  }

  static isLocalhost(): boolean {
    return window.location.href.includes('localhost');
  }

  static getAuthUrl(): string {
    return !HttpUtils.isLocalhost()
      ? environment.baseUrl + '/authorization'
      : '/authorization';
  }

  static getSSEEurl(): string {
    return !HttpUtils.isLocalhost() ? environment.baseUrl + '/sse' : '/sse';
  }
}

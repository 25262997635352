import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import BaseTrait from 'src/be-models/interfaces/traits/trait.interface';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root',
})
export class TraitService {
  constructor(private http: HttpService) {}
  getAllTraits(): Observable<BaseTrait[]> {
    return this.http.get('/traits?version=4');
  }

  getTraitsById(id: string): Observable<BaseTrait[]> {
    return this.http.get('/traits/' + id);
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
})
export class BaseModalComponent implements OnInit {
  @Input() modalTitle = '';
  @Input() defaultButtons = false;
  @Input() bottomLabel = undefined;
  @Output() confirm = new EventEmitter();
  // @HostBinding('attr.draggable') draggable = true;

  modalElement: HTMLElement;
  dragStartOffsetX = 0;
  dragStartOffsetY = 0;

  eventHandler = (event) => {
    event.preventDefault();
    console.log('preventDefaultDragover');
  };

  constructor(private modalCtrl: ModalController) {
    document.addEventListener('dragover', this.eventHandler);
    this.modalElement = document.querySelector('ion-modal');
  }

  @HostListener('dragstart', ['$event'])
  dragStart(event: DragEvent) {
    this.dragStartOffsetX = event.offsetX;
    this.dragStartOffsetY = event.offsetY;

    // event.dataTransfer.effectAllowed = 'move';
    // event.dataTransfer.dropEffect = 'move';

    // this.removeOldStyleShadowRoot('styleForDrag2', this.modalElement);
    // this.setStylesShadowRoot(
    //   'styleForDrag2',
    //   `:host {opacity: 0.1}`,
    //   this.modalElement
    // );

    event.dataTransfer.setDragImage(
      this.getModalWrapperElement(),
      this.dragStartOffsetX,
      this.dragStartOffsetY
    );
    console.log(event);
  }

  @HostListener('dragend', ['$event'])
  dragEndHandler(event) {
    event.preventDefault();
    console.log(event);

    this.removeOldStyleShadowRoot('styleForDrag', this.modalElement);
    this.setStylesShadowRoot(
      'styleForDrag',
      `.modal-wrapper {position: absolute; top: ${
        event.y - this.dragStartOffsetY
      }px; left: ${event.x - this.dragStartOffsetX}px;}`,
      this.modalElement
    );
    // ion-modal  {

    //   &::part(backdrop) {
    //     --backdrop-opacity: 0.2;
    //     --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    // }

    // &::part(content) {
    //   border-radius: 5px;
    // }
    // event.x
    // event.y
  }

  ngOnInit() {}

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.eventHandler);
  }

  setStylesShadowRoot(
    styleId: string = 'defaultShadowRootStyle',
    styleString = '',
    shadowRootHost: HTMLElement
  ) {
    if (!styleString) {
      return;
    }
    const style = document.createElement('style');
    style.id = styleId;
    style.innerHTML = styleString;
    shadowRootHost.shadowRoot.appendChild(style);
  }

  removeOldStyleShadowRoot(
    styleId: string = 'defaultShadowRootStyle',
    shadowRootHost: HTMLElement
  ) {
    for (const el of Array.from(shadowRootHost.shadowRoot.children)) {
      if (el.id === styleId) {
        shadowRootHost.shadowRoot.removeChild(el);
      }
    }
  }

  getModalWrapperElement(): HTMLElement {
    let modalWrapper = undefined;
    for (const el of Array.from(this.modalElement.shadowRoot.children)) {
      if (el.getAttribute('part') === 'content') {
        modalWrapper = el;
      }
    }
    return modalWrapper;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuPopoverComponent } from './context-menu-popover.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [ContextMenuPopoverComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [ContextMenuPopoverComponent]
})
export class ContextMenuPopoverModule { }

import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, shareReplay } from 'rxjs';
import Condition from 'src/be-models/interfaces/conditions/condition';
import { ConditionService } from '../services/conditions-service/conditions.service';

@Injectable({
  providedIn: 'root',
})
export class ConditionsStoreService {
  conditions$: Observable<Condition[]> = this.conditionService
    .getAllConditions()
    .pipe(shareReplay(1));

  get getConditions() {
    return lastValueFrom(this.conditions$);
  }
  constructor(private conditionService: ConditionService) {}
}

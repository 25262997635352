import { Injectable } from '@angular/core';
import {
  Subject,
  Subscription,
  concatMap,
  ObservableInput,
  BehaviorSubject,
  merge,
  Observable,
  map,
  switchMap,
  tap,
  from,
  finalize,
  takeUntil,
} from 'rxjs';
import { AppService } from '../services/app.service';
import { SessionActionExternalPayload } from './action-external-payload';

export interface RefusedActionPayload {
  operationId: string;
  reason: string;
}

export class BaseManagerActions<T = any> {
  resolveLocalActionsRequest$: Subject<T[]> = new Subject<T[]>();
  resolveLocalActionsRequestWithCustomId$: Subject<{
    actions: T[];
    id: string;
  }> = new Subject<{ actions: T[]; id: string }>();
}

export interface SuccessMergedActionPayload<T> {
  payload: SessionActionExternalPayload<T>;
  source: 'others' | 'own';
}

export class BaseExternalManagerActions<T = any> {
  reSynchroniseWithServer$: Subject<RefusedActionPayload> =
    new Subject<RefusedActionPayload>();

  resolveOthersActions$: Subject<SessionActionExternalPayload<T>> = new Subject<
    SessionActionExternalPayload<T>
  >();

  resolveOwnActions$: Subject<SessionActionExternalPayload<T>> = new Subject<
    SessionActionExternalPayload<T>
  >();

  resolveSuccessAction$: Observable<SuccessMergedActionPayload<T>> = merge(
    this.resolveOthersActions$.pipe(
      map<SessionActionExternalPayload<T>, SuccessMergedActionPayload<T>>(
        (x) => ({ payload: x, source: 'others' })
      )
    ),
    this.resolveOwnActions$.pipe(
      map<SessionActionExternalPayload<T>, SuccessMergedActionPayload<T>>(
        (x) => ({ payload: x, source: 'own' })
      )
    )
  );

  // outbound subject to request actions
  requestActions$: Subject<SessionActionExternalPayload<T>> = new Subject<
    SessionActionExternalPayload<T>
  >();
}

@Injectable()
export abstract class BaseRegistersManager<T = any> {
  // projectedState$: BehaviorSubject<T> = new BehaviorSubject<T>(undefined);
  // serverState$: BehaviorSubject<T> = new BehaviorSubject<T>(undefined);

  projectedStateResetTrigger$ = new Subject<void>();
  private projectedStateInternalQueueSubject$$ = new Subject<
    SessionActionExternalPayload<T>
  >();

  projectedStateCounter = 0;
  projectedStateProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  projectedStateUpdateQueue$: Subject<SessionActionExternalPayload<T>> =
    new Subject<SessionActionExternalPayload<T>>();
  serverStateUpdateQueue$: Subject<SessionActionExternalPayload<T>> =
    new Subject<SessionActionExternalPayload<T>>();

  queuedRequestedOperations: SessionActionExternalPayload<T>[] = [];
  externalActions: BaseExternalManagerActions<T>;
  protected subscriptions: Subscription = new Subscription();

  constructor(protected appService: AppService) {}

  protected conditionForUpdateOnOwnOperationAfterFailure(payload: {
    operationId: string;
  }): boolean {
    console.log(JSON.parse(JSON.stringify(this.queuedRequestedOperations)));
    console.log(
      this.queuedRequestedOperations.some(
        (x) =>
          x.operationId === payload.operationId &&
          x.timestamp < this.appService.refreshView$.value
      )
    );
    return this.queuedRequestedOperations.some(
      (x) =>
        x.operationId === payload.operationId &&
        x.timestamp < this.appService.refreshView$.value
    );
  }

  protected removeOperationFromQueuedList(payload: {
    operationId: string;
  }): void {
    this.queuedRequestedOperations = this.queuedRequestedOperations.filter(
      (x) => x.operationId !== payload.operationId
    );
  }

  protected propagateFailureWithTimestamp(payload: { operationId: string }) {
    this.appService.refreshView$.next(
      Date.now()
      // this.queuedRequestedOperations.find(
      //   (x) => x.operationId === payload.operationId
      // )?.timestamp
    );
  }

  protected initializeReSynchroniseWithServer(
    callback: (payload: RefusedActionPayload) => ObservableInput<any>
  ) {
    this.subscriptions.add(
      this.externalActions.reSynchroniseWithServer$
        .pipe(concatMap(callback))
        .subscribe()
    );
  }

  protected initializeResolveOthersActions(
    callback: (payload: SessionActionExternalPayload<T>) => ObservableInput<any>
  ) {
    this.subscriptions.add(
      this.externalActions.resolveOthersActions$
        .pipe(concatMap(callback))
        .subscribe()
    );
  }

  protected initializeResolveOwnActions(
    callback: (payload: SessionActionExternalPayload<T>) => ObservableInput<any>
  ) {
    this.subscriptions.add(
      this.externalActions.resolveOwnActions$
        .pipe(concatMap(callback))
        .subscribe()
    );
  }

  protected initializeResolveSuccessAction(
    callback: (payload: SuccessMergedActionPayload<T>) => ObservableInput<any>
  ) {
    this.subscriptions.add(
      this.externalActions.resolveSuccessAction$
        .pipe(concatMap(callback))
        .subscribe()
    );
  }

  protected initializeProjectedStateUpdateQueue(
    callback: (payload: SessionActionExternalPayload<T>) => ObservableInput<any>
  ) {
    // OG
    // this.subscriptions.add(
    // this.projectedStateResetTrigger$.pipe(
    //   tap(() => {console.log('resetting the projectedStateUpdateQueue'); this.projectedStateCounter = 0}),
    //   switchMap(() =>
    //     this.projectedStateInternalQueueSubject$$.pipe(
    //       concatMap((callback) =>
    //         from(callback()).pipe(
    //           takeUntil(this.projectedStateResetTrigger$) // Cancel ongoing work if resetSubject emits again
    //         )
    //       )
    //     )
    //   )
    // ).subscribe());

    this.subscriptions.add(
      this.projectedStateResetTrigger$
        .pipe(
          tap(() => {
            console.log('resetting the projectedStateUpdateQueue');
            this.projectedStateCounter = 0;
            this.projectedStateProcessing$.next(false);
          }),
          switchMap(() =>
            this.projectedStateInternalQueueSubject$$.pipe(
              tap((x) => {
                this.projectedStateCounter++;
                this.projectedStateProcessing$.next(true);
              }),
              concatMap((payload) => {
                return from(callback(payload)).pipe(
                  takeUntil(this.projectedStateResetTrigger$),
                  finalize(() => {
                    this.projectedStateCounter--;
                    if (this.projectedStateCounter === 0) {
                      this.projectedStateProcessing$.next(false);
                    }
                  })
                );
              })
            )
          )
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.projectedStateUpdateQueue$.subscribe((x) =>
        this.projectedStateInternalQueueSubject$$.next(x)
      )
    );
    // needs to kick off the pipes that subscribe to inner observable for the first time.
    this.projectedStateResetTrigger$.next();

    // this.subscriptions.add(
    //   this.projectedStateUpdateQueue$
    //     .pipe(
    //       tap((x) => {
    //         this.projectedStateCounter++;
    //         this.projectedStateProcessing$.next(true);
    //       }),
    //       concatMap((payload) => {
    //         return from(callback(payload)).pipe(
    //           finalize(() => {
    //             this.projectedStateCounter--;
    //             if (this.projectedStateCounter === 0) {
    //               this.projectedStateProcessing$.next(false);
    //             }
    //           })
    //         );
    //       })
    //     )
    //     .subscribe()
    // );
  }

  protected initializeServerStateUpdateQueue(
    callback: (payload: SessionActionExternalPayload<T>) => ObservableInput<any>
  ) {
    this.subscriptions.add(
      this.serverStateUpdateQueue$.pipe(concatMap(callback)).subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Condition from 'src/be-models/interfaces/conditions/condition';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root',
})
export class ConditionService {
  constructor(private http: HttpService) {}
  getAllConditions(): Observable<Condition[]> {
    return this.http.get('/conditions?version=4');
  }

  getConditionsById(id: string): Observable<Condition[]> {
    return this.http.get('/conditions/' + id);
  }
}

import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  lastValueFrom,
  map,
  Observable,
  shareReplay,
  tap,
} from 'rxjs';
import BaseTrait, {
  TraitPopulated,
} from 'src/be-models/interfaces/traits/trait.interface';
import { TraitService } from '../services/traits-service/traits.service';
import { AbilitiesStoreService } from './abilities.store';

@Injectable({
  providedIn: 'root',
})
export class TraitStoreService {
  public traits$ = this.traitService.getAllTraits().pipe(shareReplay(1));
  public traitsPopulated$: Observable<TraitPopulated[]> = combineLatest([
    this.abilitiesStore.abilities$,
    this.traits$,
  ]).pipe(
    // // TODO: remove, debug purposes
    // tap(([ab, tr]) => {
    //   const missingAbilities = [];
    //   tr.forEach((x) =>
    //     x.abilities.forEach((id) => {
    //       if (!ab.find((z) => z.id === id)) {
    //         missingAbilities.push(id);
    //         // console.error('ability not found, ability id: ', id);
    //       }
    //     })
    //   );
    //   console.error(missingAbilities);
    // }),
    map(([ab, tr]) =>
      tr.map((y) => ({
        ...y,
        abilities: y.abilities.map((id) => ab.find((z) => z.id === id)),
      }))
    )
  );

  get getTraits() {
    return lastValueFrom(this.traits$);
  }

  get getTraitPopulated() {
    return lastValueFrom(this.traitsPopulated$);
  }

  constructor(
    private traitService: TraitService,
    private abilitiesStore: AbilitiesStoreService
  ) {}

  // fetchTraits(): Observable<BaseTrait[]> {
  //   return this.traitService.getAllTraits().pipe(shareReplay(1));
  // }
}

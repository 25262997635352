import { CreateBaseCreatureRequest } from 'src/be-models/dtos/create-character-request';
import { AbilityExchangeCombination } from 'src/be-models/enums/ability-exchange-combination';
import { Attributes } from 'src/be-models/interfaces/characters/attributes';
import {
  BaseCreature,
  CreaturePopulated,
} from 'src/be-models/interfaces/characters/base-creature';
import { CharacterPopulated } from 'src/be-models/interfaces/characters/character';
import { CustomMonsterAbility } from 'src/be-models/interfaces/characters/monster';
import { PatronsCharInfo } from 'src/be-models/interfaces/characters/patrons';
import Condition from 'src/be-models/interfaces/conditions/condition';
import ConsumableItemType from 'src/be-models/interfaces/item-definitions/types/consumable-type.interface';
import { NoteWithoutContent } from 'src/be-models/interfaces/notes/note';
import {
  DefaultAction,
  DefaultEntityPayload,
} from '../action-external-payload';

export enum CharDetailsActionType {
  ManualValueUpdated = 'ManualValueUpdated',
  ItemEaten = 'ItemEaten',
  ConditionsUpdated = 'ConditionsUpdated',
  CustomConditionsUpdated = 'CustomConditionsUpdated',
  LevelUp = 'LevelUp',
  NextLevelAllowed = 'NextLevelAllowed',
  DayPassedUpdate = 'DayPassedUpdate',
  CharCreated = 'CharCreated',
  AbilitiesUpdated = 'AbilitiesUpdated',

  // (only on BE) these actions are to be only created and send from within hideout resolver
  // , not to be standalone actions
  AssignNpcToHideout = 'AssignNpcToHideout',
  UnassignNpcFromHideout = 'UnassignNpcFromHideout',
  AssignCharToFacility = 'AssignCharToFacility',
  UnassignCharFromFacility = 'UnassignCharFromFacility',
  // end of internal actions

  CreateNote = 'CreateNote',
  DeleteNote = 'DeleteNote',
  ChangeNoteTitle = 'ChangeNoteTitle',

  NPCDescriptionUpdated = 'NPCDescriptionUpdated',
  NPCLevelUpdated = 'NPCLevelUpdated',

  UpdateCustomAbility = 'UpdateCustomAbility',
  ChangeGroupId = 'ChangeGroupId',
  CharRemovalRequested = 'CharRemovalRequested',
  CharRemovalFinalized = 'CharRemovalFinalized',

  UpdatePatrons = 'UpdatePatrons',

  CharCreationRequested = 'CharCreationRequested',
  CharCreationFinalized = 'CharCreationFinalized',
}

export interface NewManualPropertyValuePair {
  propertyPath: string;
  value: any;
}

export interface ConditionIdWithAmount {
  id: string;
  amountOfStacks?: number;
}

export enum WritableStatCategory {
  Attributes = 'Attributes',
  AttackBonus = 'AttackBonus',
  TrackCurrent = 'TrackCurrent',
  TrackMax = 'TrackMax',
  DIMD = 'DIMD',
  ArmorStat = 'ArmorStats',
  ResistanceStats = 'ResistanceStats',
  Skill = 'Skill',
  ToHitBonus = 'ToHitBonus',
}

export class CharDetailsAction
  implements
    DefaultAction<CharDetailsActionType, CharDetailsActionExternalPayload>
{
  constructor(
    public actionType: CharDetailsActionType,
    public payload: CharDetailsActionExternalPayload,
    public isSecret?: boolean
  ) {}
}

export class CharDetailsActionExternalPayload implements DefaultEntityPayload {
  constructor(characterDetails: CreaturePopulated | BaseCreature) {
    this.id = characterDetails.id;
    this.dateModified = characterDetails.dateModified;
  }
  id: string;
  dateModified?: number;
}

export class CharDetailsManualValueUpdatedUpdateAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public characterStatsManualValueUpdatedDetails: NewManualPropertyValuePair,
    isSecret?: boolean
  ) {
    super(CharDetailsActionType.ManualValueUpdated, payload, isSecret);
  }
}

export class CharDetailsItemEatenAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    itemEatenDetails: Partial<ConsumableItemType>,
    usesAmount = 1
  ) {
    super(CharDetailsActionType.ItemEaten, payload);
    const { hydration, nutrition, foodCategory } = itemEatenDetails;
    this.itemEatenDetails = {
      hydration: hydration * usesAmount,
      nutrition: nutrition * usesAmount,
      foodCategory,
    };
  }
  itemEatenDetails: Partial<ConsumableItemType>;
}

export class CharDetailsConditionsUpdatedAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public conditionsToRemove?: ConditionIdWithAmount[],
    public conditionsToAdd?: ConditionIdWithAmount[]
  ) {
    super(CharDetailsActionType.ConditionsUpdated, payload);
  }
}

export class CharDetailsCustomConditionsUpdatedAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public conditionsToRemove?: string[],
    public conditionsToAdd?: Condition[]
  ) {
    super(CharDetailsActionType.CustomConditionsUpdated, payload);
  }
}

export class CharDetailsNextLevelAllowedAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public disallow = false
  ) {
    super(CharDetailsActionType.NextLevelAllowed, payload);
  }
}

export class CharDetailsLevelUpAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    // whole object!
    public updatedAttributes: Attributes,
    public abilitiesChosenIds: string[],
    public abilityExchange?: AbilityExchangeCombination // newLevel: number;
  ) {
    super(CharDetailsActionType.LevelUp, payload);
  }
}

export class CharDetailsDayPassedAction extends CharDetailsAction {
  constructor(public payload: CharDetailsActionExternalPayload) {
    super(CharDetailsActionType.DayPassedUpdate, payload);
  }
}

export class CharDetailsCharCreatedAction extends CharDetailsAction {
  constructor(public newCharacter: BaseCreature) {
    const charPayload = new CharDetailsActionExternalPayload(newCharacter);
    super(CharDetailsActionType.CharCreated, charPayload);
  }
}

export class CharDetailsAbilitiesUpdatedAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public abilitiesToRemoveIds?: string[],
    public abilitiesToAddIds?: string[]
  ) {
    super(CharDetailsActionType.AbilitiesUpdated, payload);
  }
}

export class CharDetailsAssignToHideoutAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public targetHideoutId: string
  ) {
    super(CharDetailsActionType.AssignNpcToHideout, payload);
  }
}

export class CharDetailsUnassignFromHideoutAction extends CharDetailsAction {
  constructor(public payload: CharDetailsActionExternalPayload) {
    super(CharDetailsActionType.UnassignNpcFromHideout, payload);
  }
}

export class CharDetailsAssingToFacilityAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public targetHideout: string,
    public facilityHideoutId: string
  ) {
    super(CharDetailsActionType.AssignCharToFacility, payload);
  }
}

export class CharDetailsUnassignFromFacilityAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public hideoutId: string,
    public facilityId: string
  ) {
    super(CharDetailsActionType.UnassignCharFromFacility, payload);
  }
}

export class CharDetailsCreateNoteAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public notePayload: NoteWithoutContent
  ) {
    super(CharDetailsActionType.CreateNote, payload);
  }
}

export class CharDetailsDeleteNoteAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public noteToDeleteId: string
  ) {
    super(CharDetailsActionType.DeleteNote, payload);
  }
}

export class CharDetailsChangeNoteTitleAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public noteToChangeId: string,
    public newTitle: string
  ) {
    super(CharDetailsActionType.ChangeNoteTitle, payload);
  }
}

export class CharDetailsNPCDescriptionUpdatedAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public description: string
  ) {
    super(CharDetailsActionType.NPCDescriptionUpdated, payload);
  }
}

export class CharDetailsNPCLevelUpdatedAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public newLevel: number
  ) {
    super(CharDetailsActionType.NPCLevelUpdated, payload);
  }
}

export class CharDetailsCustomAbilityUpdatedAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public abilitiesToAdd?: CustomMonsterAbility[],
    public abilitiesIdToRemove?: string[]
  ) {
    super(CharDetailsActionType.UpdateCustomAbility, payload);
  }
}

export class CharDetailsGroupUpdatedAction extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public newGroupId: string
  ) {
    super(CharDetailsActionType.ChangeGroupId, payload);
  }
}

export class CharDetailsCharRemovedRequested extends CharDetailsAction {
  constructor(public payload: CharDetailsActionExternalPayload) {
    super(CharDetailsActionType.CharRemovalRequested, payload);
  }
}

export class CharDetailsCharRemovedFinalized extends CharDetailsAction {
  constructor(public payload: CharDetailsActionExternalPayload) {
    super(CharDetailsActionType.CharRemovalFinalized, payload);
  }
}

export class CharDetailsUpdatePatrons extends CharDetailsAction {
  constructor(
    public payload: CharDetailsActionExternalPayload,
    public patrons: PatronsCharInfo
  ) {
    super(CharDetailsActionType.UpdatePatrons, payload, true);
  }
}

export class CharDetailsCharCreationRequested extends CharDetailsAction {
  constructor(
    public newCharacterRequest: CreateBaseCreatureRequest<BaseCreature>
  ) {
    const charPayload = new CharDetailsActionExternalPayload(
      newCharacterRequest.characters[0]
    );
    super(CharDetailsActionType.CharCreationRequested, charPayload);
  }
}

export class CharDetailsCharCreationFinalized extends CharDetailsAction {
  constructor(public newCharacter: BaseCreature) {
    const charPayload = new CharDetailsActionExternalPayload(newCharacter);
    super(CharDetailsActionType.CharCreationFinalized, charPayload);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpService) {}

  resetGameRegistries(gameId: string): Observable<boolean> {
    return this.http.get<boolean>('/admin/resetGameStore/' + gameId);
  }
}

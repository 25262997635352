import { Type } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AutoPositioningInfoPopoverComponent } from './auto-positioning-info-popover.component';

export class AutoPositioningInfoPopoverLogic {
  constructor(private popoverController: PopoverController) {}

  public async executeSimpleTextPopoverCreation(
    simpleText: string,
    triggerEvent: PointerEvent,
    component: Type<unknown> = AutoPositioningInfoPopoverComponent
  ) {
    const popover = await this.popoverController.create({
      component,
      componentProps: { text: simpleText },
      reference: 'event',
      event: triggerEvent,
      cssClass: 'hidden-popover',
    });

    console.log(triggerEvent);

    // this.calculatePopoverPosition(popover, event);
    await popover.present();
    this.adjustPopoverPosition(popover, triggerEvent);
    popover.classList.remove('hidden-popover');
  }

  private adjustPopoverPosition(
    popoverElement: HTMLElement,
    triggerEvent: PointerEvent
  ) {
    const contentElement = popoverElement?.shadowRoot?.querySelector(
      '.popover-content'
    ) as HTMLElement;
    const arrowElement = popoverElement?.shadowRoot?.querySelector(
      '.popover-arrow'
    ) as HTMLElement;
    if (!contentElement) {
      return undefined;
    }

    const clientRect = contentElement.getBoundingClientRect();
    const isBottomOutside = clientRect.bottom > window.innerHeight;
    const isRightOutside = clientRect.right > window.innerWidth;
    const isTopOutside = clientRect.top < 0;
    const isLeftOutside = clientRect.left < 0;

    // If needed, add more steps - can also widen the content etc.
    if (isBottomOutside) {
      if (triggerEvent.clientY - clientRect.height > 0) {
        contentElement.style.top =
          triggerEvent.clientY - clientRect.height + 'px';

        // transform: rotate(180deg);
      } else if (clientRect.height < window.innerHeight) {
        contentElement.style.top = 0 + 'px';
      }
      // final fallback
      else {
        contentElement.style.top = 0 + 'px';
        contentElement.style.left = 0 + 'px';
        contentElement.style.width = '90%';
      }
      if (arrowElement) {
        arrowElement.style.transform = 'rotate(180deg)';
      }
    }

    console.log(
      contentElement,
      JSON.parse(JSON.stringify(clientRect))
      // this.hostElement
    );
  }
}

import { AlertController } from '@ionic/angular';
import { InGameSettingsActionChange } from 'src/be-models/dtos/edit-game-request';
import {
  GamePopulated,
  GamePopulatedRegistry,
} from 'src/be-models/interfaces/games/game';
import { GameDetailsSnapshotContent } from 'src/be-models/interfaces/snapshot/game-details-snapshot.interface';
import {
  GameDetailsAction,
  GameDetailsActionType,
  GameDetailsAnomalyDicePoolRolledOrdered,
  GameDetailsAnomalyDicePoolRolledResponse,
  GameDetailsAnomalyDicePoolUpdated,
  GameDetailsFeedUpdatedAction,
  GameDetailsInGameSettingsUpdate,
  GameDetailsNpcMonsterGroupChangedAction,
  GameDetailsNpcMonsterGroupCreatedAction,
  GameDetailsNpcMonsterGroupDeletedAction,
} from './game-details.actions';

export class GameDetailsSingleActionRegistryResolver {
  constructor() {}

  public resolveGameDetailsSingleActionRegistry(
    action: GameDetailsAction,
    gameDetails: GameDetailsSnapshotContent,
    timestamp: number
  ): GameDetailsSnapshotContent {
    console.log('gameDetails bulkOperationResolver', action);

    switch (action.actionType) {
      case GameDetailsActionType.DayPassed: {
        gameDetails.inGameDate += 24 * 60 * 60 * 1000;

        // this.updateTimestamp(gameDetails, timestamp);
        break;
      }

      case GameDetailsActionType.FeedUpdated: {
        const gameFeedAction = action as GameDetailsFeedUpdatedAction;
        gameDetails.gameFeed.logs.push(gameFeedAction.gameFeedRecord);
        // this.updateTimestamp(gameDetails, timestamp);

        break;
      }

      case GameDetailsActionType.NpcMonsterGroupCreated: {
        const npcMonsterGroupCreatedAction =
          action as GameDetailsNpcMonsterGroupCreatedAction;

        gameDetails.npcGroups[npcMonsterGroupCreatedAction.groupId] = [];
        // this.updateTimestamp(gameDetails, timestamp);

        break;
      }

      case GameDetailsActionType.NpcMonsterGroupChanged: {
        const npcMonsterGroupChangedAction =
          action as GameDetailsNpcMonsterGroupChangedAction;

        this.updateGroup(
          gameDetails,
          npcMonsterGroupChangedAction.groupToBeUpdatedId,
          npcMonsterGroupChangedAction.creatureToAddDetails,
          npcMonsterGroupChangedAction.creatureIdsToRemove
        );

        // this.updateTimestamp(gameDetails, timestamp);
        break;
      }

      case GameDetailsActionType.NpcMonsterGroupDeleted: {
        const npcMonsterGroupDeletedAction =
          action as GameDetailsNpcMonsterGroupDeletedAction;

        this.deleteGroup(
          gameDetails,
          npcMonsterGroupDeletedAction.groupToBeDeletedId
        );

        // this.updateTimestamp(gameDetails, timestamp);
        break;
      }

      case GameDetailsActionType.AnomalyDicePoolUpdated: {
        const anomalyDicePoolUpdatedAction =
          action as GameDetailsAnomalyDicePoolUpdated;

        gameDetails.anomalyLootPoolNumber =
          anomalyDicePoolUpdatedAction.newValue;
        break;
      }

      case GameDetailsActionType.AnomalyDicePoolRolledResponse: {
        const anomalyDicePoolRolledResponseAction =
          action as GameDetailsAnomalyDicePoolRolledResponse;
        gameDetails.anomalyCurrentEffect =
          anomalyDicePoolRolledResponseAction.rolledResult;
        break;
      }

      case GameDetailsActionType.AnomalyDicePoolRolledOrdered:
      case GameDetailsActionType.AnomalyDicePoolReset: {
        gameDetails.anomalyLootPoolNumber = 0;
        break;
      }

      case GameDetailsActionType.IngameSettingsChanged: {
        const ingameSettingsAction = action as GameDetailsInGameSettingsUpdate;

        gameDetails.inGameDate =
          ingameSettingsAction.newGamesInGameSettings.inGameDate;
        gameDetails.inGameSettings =
          ingameSettingsAction.newGamesInGameSettings.inGameSettings;
        break;
      }

      default:
        break;
    }

    if (!action.isSecret) {
      this.updateTimestamp(gameDetails, timestamp);
    }

    return gameDetails;
  }

  private updateTimestamp(
    gameDetails: { dateModified?: number; id: string },
    timestamp: number
  ) {
    gameDetails.dateModified = timestamp;
  }

  private updateGroup(
    gameDetails: GameDetailsSnapshotContent,
    groupToBeUpdatedId: string,
    creatureToAddDetails:
      | { id: string; position: number | undefined; sort?: boolean | undefined }
      | undefined,
    creatureIdsToRemove: string[] | undefined
  ) {
    const addActionCondition =
      creatureToAddDetails && !creatureToAddDetails.sort;
    const sortActionCondition =
      creatureToAddDetails && creatureToAddDetails.sort;
    const removeActionCondition =
      creatureIdsToRemove !== undefined && creatureIdsToRemove.length > 0;

    if (!gameDetails.npcGroups[groupToBeUpdatedId]) {
      return;
    }

    if (addActionCondition) {
      gameDetails.npcGroups[groupToBeUpdatedId].splice(
        creatureToAddDetails.position ??
          gameDetails.npcGroups[groupToBeUpdatedId].length,
        0,
        creatureToAddDetails.id
      );
    }

    if (removeActionCondition) {
      gameDetails.npcGroups[groupToBeUpdatedId] = gameDetails.npcGroups[
        groupToBeUpdatedId
      ].filter((x) => !creatureIdsToRemove.includes(x));
    }

    if (sortActionCondition) {
      gameDetails.npcGroups[groupToBeUpdatedId].splice(
        creatureToAddDetails.position!,
        0,
        gameDetails.npcGroups[groupToBeUpdatedId].splice(
          gameDetails.npcGroups[groupToBeUpdatedId].findIndex(
            (x) => x === creatureToAddDetails.id
          ),
          1
        )[0]
      );
    }
  }

  private deleteGroup(
    gameDetails: GameDetailsSnapshotContent,
    groupToBeRemovedId: string
  ) {
    delete gameDetails.npcGroups[groupToBeRemovedId];
  }
}

import { Injectable } from '@angular/core';
import {
  combineLatest,
  lastValueFrom,
  map,
  Observable,
  shareReplay,
  zip,
} from 'rxjs';
import Background, {
  BackgroundPopulated,
} from 'src/be-models/interfaces/backgrounds/background.interface';
import { BackgroundsService } from '../services/backgrounds-service/backgrounds.service';
import { AbilitiesStoreService } from './abilities.store';

@Injectable({
  providedIn: 'root',
})
export class BackgroundStoreService {
  public backgrounds$ = this.backgroundService
    .getAllBackgrounds()
    .pipe(shareReplay(1));
  public backgroundsPopulated$: Observable<BackgroundPopulated[]> =
    combineLatest([this.abilitiesStore.abilities$, this.backgrounds$]).pipe(
      map(([ab, bg]) =>
        bg.map((y) => ({
          ...y,
          abilities: y.abilities.map((id) => ab.find((z) => z.id === id)),
        }))
      )
    );

  get getBackgrounds() {
    return lastValueFrom(this.backgrounds$);
  }

  get getBackgroundPopulated() {
    return lastValueFrom(this.backgroundsPopulated$);
  }

  constructor(
    private backgroundService: BackgroundsService,
    private abilitiesStore: AbilitiesStoreService
  ) {}
}

import { HostListener, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export enum ScreenSizeMode {
  xs,
  s,
  m,
  l,
  xl,
  // Full desktop
  xxl,
}

export interface ScreenSizeDetailsState {
  widthInPx: number;
  heightInPx: number;
  screenMode: ScreenSizeMode;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private _state$: BehaviorSubject<ScreenSizeDetailsState>;
  public state$: Observable<ScreenSizeDetailsState>;

  public isMobile$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public getCurrentState: () => ScreenSizeDetailsState = () =>
    this._state$.getValue();

  constructor() {
    this._state$ = new BehaviorSubject({
      widthInPx: window.innerWidth,
      heightInPx: window.innerHeight,
      screenMode: this.calculateScreenMode(),
    });
    this.state$ = this._state$.asObservable();
    fromEvent(window, 'resize')
      .pipe(debounceTime(150))
      .subscribe((x) => {
        this._state$.next({
          widthInPx: window.innerWidth,
          heightInPx: window.innerHeight,
          screenMode: this.calculateScreenMode(),
        });
      });
  }

  calculateScreenMode(): ScreenSizeMode {
    const widthInPx = (window as Window).innerWidth;
    if (widthInPx < 600) {
      this.isMobile$.next(true);
      return ScreenSizeMode.xs;
    } else if (widthInPx < 768) {
      this.isMobile$.next(true);
      return ScreenSizeMode.s;
    } else if (widthInPx < 992) {
      this.isMobile$.next(false);
      return ScreenSizeMode.m;
    } else if (widthInPx < 1200) {
      this.isMobile$.next(false);
      return ScreenSizeMode.l;
    } else if (widthInPx < 1728) {
      this.isMobile$.next(false);
      return ScreenSizeMode.xl;
    } else if (widthInPx >= 1728) {
      this.isMobile$.next(false);
      return ScreenSizeMode.xxl;
    }
  }
}

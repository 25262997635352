import { InGameSettingsActionChange } from 'src/be-models/dtos/edit-game-request';
import { AnomalyDicePoolEffect } from 'src/be-models/interfaces/anomaly-dice-pool-effect/anomaly-dice-pool-effect.interface';
import { GameFeedRecord } from 'src/be-models/interfaces/game-feed/game-feed.interface';
import { GamePopulatedRegistry } from 'src/be-models/interfaces/games/game';
import { GameDetailsSnapshotContent } from 'src/be-models/interfaces/snapshot/game-details-snapshot.interface';
import {
  DefaultAction,
  DefaultEntityPayload,
} from '../action-external-payload';

export enum GameDetailsActionType {
  DayPassed = 'DayPassed',
  FeedUpdated = 'FeedUpdated',
  NpcMonsterGroupCreated = 'NpcMonsterGroupCreated',
  NpcMonsterGroupChanged = 'NpcMonsterGroupChanged',
  NpcMonsterGroupDeleted = 'NpcMonsterGroupDeleted',
  AnomalyDicePoolUpdated = 'AnomalyDicePoolUpdated',
  AnomalyDicePoolRolledOrdered = 'AnomalyDicePoolRolledOrdered',
  AnomalyDicePoolRolledResponse = 'AnomalyDicePoolRolledResponse',
  AnomalyDicePoolReset = 'AnomalyDicePoolReset',
  IngameSettingsChanged = 'IngameSettingsChanged',
}

export class GameDetailsAction
  implements
    DefaultAction<GameDetailsActionType, GameDetailsActionExternalPayload>
{
  constructor(
    public actionType: GameDetailsActionType,
    public payload: GameDetailsActionExternalPayload,
    public isSecret?: boolean
  ) {}
}

export class GameDetailsActionExternalPayload implements DefaultEntityPayload {
  constructor(gameDetails: GameDetailsSnapshotContent) {
    this.id = gameDetails.id;
    this.dateModified = gameDetails.dateModified;
  }
  id: string;
  dateModified: number;
}

// TODO: is the inGameDate even needed here? or even the gameDetailsPayload?
export class GameDetailsDayPassedAction extends GameDetailsAction {
  constructor(
    public payload: GameDetailsActionExternalPayload,
    public inGameDate: number
  ) {
    super(GameDetailsActionType.DayPassed, payload);
  }
}

export class GameDetailsFeedUpdatedAction extends GameDetailsAction {
  constructor(
    public gameFeedRecord: GameFeedRecord,
    gameDetails: GameDetailsActionExternalPayload
  ) {
    super(GameDetailsActionType.FeedUpdated, gameDetails);
  }
}

export class GameDetailsNpcMonsterGroupCreatedAction extends GameDetailsAction {
  constructor(
    gameDetails: GameDetailsActionExternalPayload,
    public groupId: string
  ) {
    super(GameDetailsActionType.NpcMonsterGroupCreated, gameDetails);
  }
}

export class GameDetailsNpcMonsterGroupChangedAction extends GameDetailsAction {
  constructor(
    gameDetails: GameDetailsActionExternalPayload,
    public groupToBeUpdatedId: string,
    public creatureToAddDetails?: {
      id: string;
      position: number | undefined;
      sort?: boolean;
    },
    public creatureIdsToRemove?: string[]
  ) {
    super(GameDetailsActionType.NpcMonsterGroupChanged, gameDetails);
  }
}

export class GameDetailsNpcMonsterGroupDeletedAction extends GameDetailsAction {
  constructor(
    gameDetails: GameDetailsActionExternalPayload,
    public groupToBeDeletedId: string,
    public newGroupForNpcsId: string
  ) {
    super(GameDetailsActionType.NpcMonsterGroupDeleted, gameDetails);
  }
}

export class GameDetailsAnomalyDicePoolUpdated extends GameDetailsAction {
  constructor(
    gameDetails: GameDetailsActionExternalPayload,
    public newValue: number
  ) {
    super(GameDetailsActionType.AnomalyDicePoolUpdated, gameDetails);
  }
}

export class GameDetailsAnomalyDicePoolRolledOrdered extends GameDetailsAction {
  constructor(gameDetails: GameDetailsActionExternalPayload) {
    super(GameDetailsActionType.AnomalyDicePoolRolledOrdered, gameDetails);
  }
}

export class GameDetailsAnomalyDicePoolRolledResponse extends GameDetailsAction {
  constructor(
    gameDetails: GameDetailsActionExternalPayload,
    public rolledResult: AnomalyDicePoolEffect
  ) {
    super(
      GameDetailsActionType.AnomalyDicePoolRolledResponse,
      gameDetails,
      true
    );
  }
}

export class GameDetailsAnomalyDicePoolReset extends GameDetailsAction {
  constructor(gameDetails: GameDetailsActionExternalPayload) {
    super(GameDetailsActionType.AnomalyDicePoolReset, gameDetails);
  }
}

export class GameDetailsInGameSettingsUpdate extends GameDetailsAction {
  constructor(
    gameDetails: GameDetailsActionExternalPayload,
    public newGamesInGameSettings: InGameSettingsActionChange
  ) {
    super(GameDetailsActionType.IngameSettingsChanged, gameDetails);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GameListResponseRecord,
  GamePopulated,
  GamePopulatedRegistry,
} from 'src/be-models/interfaces/games/game';
import { HttpService } from '../../http.service';
import { CreateGameRequest } from 'src/be-models/dtos/create-game-request';
import { EditGameSettingsRequest } from 'src/be-models/dtos/edit-game-request';
import { GameDetailsSnapshotContent } from 'src/be-models/interfaces/snapshot/game-details-snapshot.interface';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  constructor(private http: HttpService) {}
  getGamesListForUser(): Observable<GameListResponseRecord[]> {
    return this.http.get('/games/mine');
  }

  getGameDetails(gameId: string): Observable<GamePopulatedRegistry> {
    return this.http.get(`/games/detailed/${gameId}`);
  }

  getGameInGameDetails(gameId: string): Observable<GameDetailsSnapshotContent> {
    return this.http.get(`/games/inGameDetails/${gameId}`);
  }

  createGame(createGamePayload: CreateGameRequest) {
    return this.http.post('/games/create', createGamePayload);
  }

  editGameSettings(editGamePayload: EditGameSettingsRequest) {
    return this.http.post('/games/edit-settings', editGamePayload);
  }

  // outdated endpoint
  updateGameOnlineStatus(id: string, status: boolean): Observable<boolean> {
    return this.http.post('/games/online-status', { id, isOnline: status });
  }

  resetGameAnomalyTable(id: string): Observable<boolean> {
    return this.http.get(`/games/reset-table/${id}`);
  }

  deleteGame(gameId: string) {
    return this.http.delete('/games/delete', [gameId]);
  }

  // invitations start
  enableDisableInvitation(newStatus: boolean, gameId: string) {
    return this.http.put('/games/update-invitation-status', {
      gameId,
      status: newStatus,
    });
  }

  generateNewInvitationCode(gameId: string) {
    return this.http.get(`/games/generate-invitation/${gameId}`);
  }

  resolveInvitationLink(invitationLink: string) {
    return this.http.get(`/games/accept-invitation/${invitationLink}`);
  }

  denyInvitation(gameId: string) {
    return this.http.get(`/games/deny-invitation/${gameId}`);
  }
  // invitations end

  // slots start
  createNewPlayerSlot(
    gameId: string
  ): Observable<{ success: boolean; id: string }> {
    return this.http.post(`/games/create-player-slot`, { gameId });
  }

  deletePlayerSlot(
    gameId: string,
    slotId: string
  ): Observable<{ success: boolean; id: string }> {
    return this.http.post(`/games/delete-player-slot`, { gameId, slotId });
  }

  assignSlotToPlayer(gameId: string, slotId: string, newUserName: string) {
    return this.http.post('/games/assing-slot-to-player', {
      gameId,
      slotId,
      newUserName,
    });
  }

  // getSlotDetails(
  //   gameId: string,
  //   slotId: string
  // ): Observable<Partial<GamePopulated>> {
  //   return this.http.post('/games/player-slot-details', { gameId, slotId });
  // }
  // slots ends
}

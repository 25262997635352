import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Ability, {
  AbilityPopulated,
} from 'src/be-models/interfaces/abilities/ability.interface';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root',
})
export class AbilitiesService {
  constructor(private http: HttpService) {}
  getAllAbilities(): Observable<Ability[]> {
    return this.http.get('/abilities?version=4');
  }

  getAbilitiesById(id: string): Observable<Ability[]> {
    return this.http.get('/abilities/' + id);
  }
}

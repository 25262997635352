import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
// import { AuthGuard } from 'src/guards/auth.guard';

const routes: Routes = [
  {
    path: 'main',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    // canLoad: [MsalGuard],
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    // canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/main-view/main-view.module').then(
        (m) => m.MainViewPageModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/main-menu/main-menu.module').then(
        (m) => m.MainMenuPageModule
      ),
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    // canLoad: [MsalGuard]
  },
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./pages/landing-page/landing-page.module').then(
  //       (m) => m.LandingPageModule
  //     ),
  // },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import StatsBonus from "../shared/stats-bonus.interface";
import { Modify } from "../utils";

export default interface Ability {
  id: string;
  // TODO: check if needed - it may be better to use directly type and tier and glue it on frontend
  name: string;
  shortName: string;
  abilityType: AbilityType;
  type: string;
  tier: number;
  description: string;
  prerequisiteAbilities: string[];
  bonus?: StatsBonus | string;
  anomalyCost?: string;
  anomalyThreshold?: string;
  anomalyRange?: string;
  anomalyToHit?: string;
  anomalyDamage?: string;
  energyClass?: number;
  controlClass?: number;
  wardClass?: number;
  energyAbility?: string;
  controlAbility?: string;
  wardAbility?: string;
}

export interface AbilityPopulated
  extends Modify<Ability, { prerequisiteAbilities: AbilityPopulated[] }> {}

export enum AbilityType {
  NonCombat = "non-combat",
  Combat = "combat",
  Anomaly = "anomaly",
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// var consoleHolder = console;
// function debug(bool) {
//   if (!bool) {
//     // consoleHolder = console;
//     console = {} as any;
//     Object.keys(consoleHolder).forEach(function (key) {
//       console[key] = function () {};
//     });
//   } else {
//     console = consoleHolder;
//   }
// }

var consoleLogHolder = console.log;
function debug(bool) {
  if (!bool) {
    // consoleHolder = console;
    console.log = function () {};
    // Object.keys(consoleHolder).forEach(function (key) {
    //   console[key] = function () {};
    // });
  } else {
    console.log = consoleLogHolder;
  }
}
// debug(false);
(window as any).debug = debug;

(window as any).debugTools = function debugTools(bool) {
  (window as any).debugToolsEnabled = bool;
};

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

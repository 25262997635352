import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuPopoverModule } from './context-menu-popover/context-menu-popover.module';
import { TooltipPopoverModule } from './tooltip-popover/tooltip-popover.module';
import { TableMenuSettingsPopoverModule } from './table-menu-settings-popover/table-menu-settings-popover.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ContextMenuPopoverModule,
    TooltipPopoverModule,
    TableMenuSettingsPopoverModule,
  ],
  exports: [ContextMenuPopoverModule, TooltipPopoverModule],
})
export class PopoversModule {}

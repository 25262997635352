import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilKeyChanged,
  map,
  Observable,
} from 'rxjs';
import GlobalUtils from 'src/app/utils/global-utils';
import { GameDetailsRegisterManagerStore } from '../game/game-details/game-details-register-manager';

export interface LocalStorageState {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private state$: BehaviorSubject<LocalStorageState> =
    new BehaviorSubject<LocalStorageState>({});
  // state$$: Observable<LocalStorageState>;

  constructor(
    private gameDetailsRegisterManager: GameDetailsRegisterManagerStore
  ) {
    // this.state$$ = this.state$.asObservable();
  }

  public selectorForKey(key: string) {
    return this.state$.pipe(
      distinctUntilKeyChanged(key),
      map((x) => x[key])
    );
  }

  public set(key: string, value: any) {
    this.state$.next({ ...this.state$.value, [key]: value });
    localStorage.setItem(key, JSON.stringify(value));
  }

  public get<T = any>(key: string): T {
    const fromState = this.state$.value[key];
    if (fromState === undefined) {
      const fromStorage = localStorage.getItem(key);
      if (fromStorage && GlobalUtils.isJson(fromStorage)) {
        const value: T = JSON.parse(fromStorage);
        this.state$.next({
          ...this.state$.value,
          [key]: value,
        });

        return value;
      }
    }
    return fromState;
  }

  public remove(elementKey: string): void {
    this.state$.next({ ...this.state$.value, elementKey: undefined });
    localStorage.removeItem(elementKey);
  }

  getStoredNpcMonsterIdForGroup(groupId: string): string {
    const charGroupsArray: [string, string][] =
      this.get('npcsSelectedCharAgGroup') ?? [];
    // JSON.parse(localStorage.getItem('npcsSelectedCharAgGroup')) ?? [];
    const characterId = charGroupsArray.find((x) => x[0] === groupId)?.[1];
    return characterId;
  }

  updateStoredNpcMonsterIdForGroup(charId: string, groupId: string) {
    const charGroupsArray: [string, string][] =
      this.get('npcsSelectedCharAgGroup') ?? [];
    // JSON.parse(localStorage.getItem('npcsSelectedCharAgGroup')) ?? [];
    const idGroupFound: [string, string] = charGroupsArray.find(
      (x) => x[0] === groupId
    );
    const charIdAlreadyInGroupFound: [string, string] = charGroupsArray.find(
      (x) => x[1] === groupId
    );

    // remove from old group
    if (charIdAlreadyInGroupFound && idGroupFound) {
      charGroupsArray.splice(charGroupsArray.indexOf(idGroupFound), 1, [
        groupId,
        undefined,
      ]);
    }

    if (idGroupFound) {
      charGroupsArray.splice(charGroupsArray.indexOf(idGroupFound), 1, [
        groupId,
        charId,
      ]);
    } else {
      charGroupsArray.push([groupId, charId]);
    }

    this.set('npcsSelectedCharAgGroup', charGroupsArray);

    // localStorage.setItem(
    //   'npcsSelectedCharAgGroup',
    //   JSON.stringify(charGroupsArray)
    // );
  }

  public getNpcsSelectedGroupSafe(): string | undefined {
    return Object.keys(
      this.gameDetailsRegisterManager.getState().npcGroups
    ).find((x) => x === this.get('npcsSelectedGroup'));
  }
}

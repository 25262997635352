import { Injectable, OnDestroy } from '@angular/core';
import deepEqual from 'deep-equal';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import { CreaturePopulated } from 'src/be-models/interfaces/characters/base-creature';

// TODO: For now, character populated seems like a good solution, but it can be even better if we
// apply system of fetching abilities ONCE and then working on unpopulated Character, populating f.ex. ablities fro mabiliteis store
export class GamesCharDetailsServiceActions {
  // TODO: is it even needed
  addCharactersToRegister$: Subject<CreaturePopulated[]> = new Subject<
    CreaturePopulated[]
  >();

  setCurrentlySelectedCharacter$: Subject<CreaturePopulated> =
    new Subject<CreaturePopulated>();
  setCurrentlySelectedCharacterById$: Subject<string> = new Subject<string>();
}

export class GamesCharDetailsServiceOfflineActions {
  // addCharactersToRegister$: Subject<CharacterPopulated[]> = new Subject<
  //   CharacterPopulated[]
  // >();
  clearCharDetailsRegistry$: Subject<void> = new Subject<void>();
  // setRegisterCharDetails$: Subject<CreaturePopulated[]> = new Subject<
  //   CreaturePopulated[]
  // >();
}

@Injectable({ providedIn: 'root' })
export class CharactersRegisterStore implements OnDestroy {
  actions: GamesCharDetailsServiceActions =
    new GamesCharDetailsServiceActions();
  offlineActions: GamesCharDetailsServiceOfflineActions =
    new GamesCharDetailsServiceOfflineActions();

  subscriptions: Subscription = new Subscription();
  public charDetailsRegistryStateProjected$: Observable<CreaturePopulated[]>;
  public projectedStateLatestOp$: ReplaySubject<string> = new ReplaySubject(1);

  // TODO: switch everything to this CSC
  currentlySelectedCharacter$: BehaviorSubject<CreaturePopulated> =
    new BehaviorSubject<CreaturePopulated>({} as any);

  get charDetailsRegistryStateProjected(): CreaturePopulated[] {
    return this.charDetailsRegistryInternalStateProjected$$.value;
  }
  get charDetailsRegistryStateServer(): CreaturePopulated[] {
    return this.charDetailsRegistryInternalStateServer$$.value;
  }

  private charDetailsRegistryInternalStateProjected$$: BehaviorSubject<
    CreaturePopulated[]
  > = new BehaviorSubject<CreaturePopulated[]>([]);

  private charDetailsRegistryInternalStateServer$$: BehaviorSubject<
    CreaturePopulated[]
  > = new BehaviorSubject<CreaturePopulated[]>([]);
  public serverStateLatestOp$: ReplaySubject<string> = new ReplaySubject(1);

  constructor() {
    this.charDetailsRegistryStateProjected$ =
      this.charDetailsRegistryInternalStateProjected$$.asObservable();
    this.registerOfflineHandlers();
    this.registerOnlineHandlers();
  }

  registerOfflineHandlers() {
    // this.offlineActions.addCharactersToRegister$.subscribe((x) => {
    //   let charDetailsArray =
    //     this.charDetailsRegistryInternalStateProjected$$.getValue();
    //   charDetailsArray = this.upsertCharDetailsToRegistry(
    //     JSON.parse(JSON.stringify(x)),
    //     charDetailsArray
    //   );
    //   // needed to trigger subscribers
    //   this.updateProjectedCharDetailsRegistryState([...charDetailsArray]);
    // });

    // this.subscriptions.add(
    //   this.offlineActions.setRegisterCharDetails$.subscribe(
    //     (newCharDetails) => {
    //       console.log('offlineActions.setRegisterCharDetails$');
    //       this.updateProjectedCharDetailsRegistryState([...newCharDetails]);
    //       this.updateServerCharDetailsRegistryState([...newCharDetails]);
    //     }
    //   )
    // );

    this.subscriptions.add(
      this.offlineActions.clearCharDetailsRegistry$.subscribe(() => {
        this.updateProjectedCharDetailsRegistryState([]);
        this.updateServerCharDetailsRegistryState([]);
      })
    );
  }

  registerOnlineHandlers() {
    this.subscriptions.add(
      this.actions.addCharactersToRegister$.subscribe(
        (newCharactersDetailed) => {
          let charDetailsArray =
            this.charDetailsRegistryInternalStateProjected$$.getValue();
          charDetailsArray = this.upsertCharDetailsToRegistry(
            JSON.parse(JSON.stringify(newCharactersDetailed)),
            charDetailsArray
          );

          // needed to trigger subscribers (like equipped item table in character sheet f.ex.)
          this.updateProjectedCharDetailsRegistryState(charDetailsArray);
          // server array mirrored
          let charDetailsServerRegistryArray =
            this.charDetailsRegistryInternalStateServer$$.getValue();

          charDetailsServerRegistryArray = this.upsertCharDetailsToRegistry(
            JSON.parse(JSON.stringify(newCharactersDetailed)),
            charDetailsServerRegistryArray
          );
          this.updateServerCharDetailsRegistryState(
            charDetailsServerRegistryArray
          );
          console.log(
            'characters: charDetailsServerRegistryArray, on initial load:',
            JSON.parse(JSON.stringify(charDetailsServerRegistryArray))
          );
        }
      )
    );

    this.subscriptions.add(
      this.actions.setCurrentlySelectedCharacter$.subscribe((char) => {
        console.log(
          'want to set: ',
          char,
          char?.id,
          'as csc, and its found as:',
          this.charDetailsRegistryStateProjected.find((y) => y.id === char?.id)
        );
        this.currentlySelectedCharacter$.next(char);
      })
    );

    this.subscriptions.add(
      this.actions.setCurrentlySelectedCharacterById$.subscribe((id) => {
        console.log(
          'want to set: ',
          id,
          'as csc, and its found as:',
          this.charDetailsRegistryStateProjected.find((y) => y.id === id)
        );
        this.currentlySelectedCharacter$.next(
          this.charDetailsRegistryStateProjected.find((y) => y.id === id)
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  upsertCharDetailsToRegistry(
    newCharDetails: CreaturePopulated[],
    charDetailsCurrentState: CreaturePopulated[]
  ) {
    for (const changedChar of newCharDetails) {
      const itemIndex = charDetailsCurrentState.findIndex(
        (x) => x.id === changedChar.id
      );
      if (itemIndex > -1) {
        charDetailsCurrentState[itemIndex] = changedChar;
      } else {
        charDetailsCurrentState.push(changedChar);
      }
    }
    return charDetailsCurrentState;
  }

  updateProjectedCharDetailsRegistryState(
    newState: CreaturePopulated[],
    latestOpId?: string
  ) {
    // const oldState = this.itemsRegistryInternalState$$.getValue();
    this.charDetailsRegistryInternalStateProjected$$.next(newState);
    this.projectedStateLatestOp$.next(latestOpId);
    // update currently selected character, too
    const updatedCurrentlySelectedChar = newState.find(
      (x) => x.id === this.currentlySelectedCharacter$.value?.id
    );

    // update Currently selected character only when changes occurs on it, not on whole state!
    // dateModified is never same so any update on csc will pass
    if (
      updatedCurrentlySelectedChar &&
      !deepEqual(
        updatedCurrentlySelectedChar,
        this.currentlySelectedCharacter$.value
      )
    ) {
      console.log('updated csc');
      this.actions.setCurrentlySelectedCharacter$.next(
        updatedCurrentlySelectedChar
      );
    }
    // }
    console.log(
      'characters: stateUpdatedProjected',
      this.charDetailsRegistryInternalStateProjected$$.getValue()
    );
  }

  updateServerCharDetailsRegistryState(
    newState: CreaturePopulated[],
    latestOpId?: string
  ) {
    // const oldState = this.itemsRegistryInternalState$$.getValue();
    this.charDetailsRegistryInternalStateServer$$.next(newState);
    console.log(
      'characters: stateUpdatedServer',
      this.charDetailsRegistryInternalStateServer$$.getValue()
    );
    this.serverStateLatestOp$.next(latestOpId);
  }
}

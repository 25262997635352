import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';
import { LoggedUserName } from 'src/app/core/api/services/auth-service/auth.service';
import {
  DiceService,
  DicesThrowResults,
  SingleDiceRollRequestData,
} from 'src/app/core/api/services/dice-service/dice.service';
import { CharactersRegisterManagerStore } from 'src/app/core/game/char-details/game-characters-register-manager.store';
import { GameDetailsRegisterManagerStore } from 'src/app/core/game/game-details/game-details-register-manager';
import {
  GameDetailsActionExternalPayload,
  GameDetailsFeedUpdatedAction,
} from 'src/app/core/game/game-details/game-details.actions';
import { ScreenSizeService } from 'src/app/core/screen-size-service/screen-size.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import GlobalUtils from 'src/app/utils/global-utils';
import { GameRole } from 'src/be-models/interfaces/games/game';

interface PreviousRoll {
  rolld6: boolean;
  amountd6: number;
  throwsd6: number;
  rolld20: boolean;
  amountd20: number;
  throwsd20: number;
  modd20: number;
}

@Component({
  selector: 'app-dice-roller',
  templateUrl: './dice-roller.component.html',
  styleUrls: ['./dice-roller.component.scss'],
})
export class DiceRollerComponent implements OnInit, OnDestroy {
  d20modifier = null;
  rolld6 = true;
  rolld20 = false;
  publishRoll = true;
  previousRolls: PreviousRoll[] = [];

  subscription: Subscription = new Subscription();
  throttler$: Subject<SingleDiceRollRequestData[]> = new Subject<
    SingleDiceRollRequestData[]
  >();

  d6AmountControl: FormControl = new FormControl(1, [
    Validators.required,
    GlobalUtils.positiveIntegerValidator,
    Validators.min(1),
    Validators.max(60),
  ]);

  d6RollsControl: FormControl = new FormControl(1, [
    Validators.required,
    GlobalUtils.positiveIntegerValidator,
    Validators.min(1),
    Validators.max(10),
  ]);

  d20AmountControl: FormControl = new FormControl(1, [
    Validators.required,
    GlobalUtils.positiveIntegerValidator,
    Validators.min(1),
    Validators.max(10),
  ]);

  d20RollsControl: FormControl = new FormControl(1, [
    Validators.required,
    GlobalUtils.positiveIntegerValidator,
    Validators.min(1),
    Validators.max(10),
  ]);

  results: DicesThrowResults[];

  constructor(
    private diceService: DiceService,
    private gameCharDetailsRegisterManagerStore: CharactersRegisterManagerStore,
    private gameDetailsRegisterManagerStore: GameDetailsRegisterManagerStore,
    private localStorageService: LocalStorageService,
    private toastCtrl: ToastController,
    public screenSizeService: ScreenSizeService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.d6AmountControl.valueChanges.subscribe((x) => {
        console.log(x);
        // this.d6amount = Math.min(x, 60);
      })
    );
    this.subscription.add(
      this.d6RollsControl.valueChanges.subscribe((x) => {
        console.log(x);
        // this.d6rolls = Math.min(x, 60);
      })
    );

    this.subscription.add(
      this.d20AmountControl.valueChanges.subscribe((x) => {
        console.log(x);
        // this.d6rolls = Math.min(x, 60);
      })
    );

    this.subscription.add(
      this.d20RollsControl.valueChanges.subscribe((x) => {
        console.log(x);
        // this.d6rolls = Math.min(x, 60);
      })
    );

    this.subscription.add(
      this.throttler$
        .pipe(throttleTime(1000))
        .subscribe((rollsRequestArray) => {
          this.diceService
            .rollDices({
              diceRolls: rollsRequestArray,
            })
            .subscribe((x) => {
              this.results = x;
              if (this.publishRoll) {
                this.gameDetailsRegisterManagerStore.actions.resolveLocalActionsRequest$.next(
                  [
                    new GameDetailsFeedUpdatedAction(
                      {
                        roll: { dicesThrowsResults: x },
                        characterName:
                          this.gameDetailsRegisterManagerStore
                            .loadedGameStaticInfo$$.value?.role === GameRole.GM
                            ? 'GM'
                            : this.gameCharDetailsRegisterManagerStore
                                .currentlySelectedCharacter$.value?.name,
                        // userId: UserDetails.,
                      },

                      new GameDetailsActionExternalPayload(
                        this.gameDetailsRegisterManagerStore.getState()
                      )
                    ),
                  ]
                );
              }
            });
        })
    );

    this.previousRolls = this.localStorageService.get('previous-rolls') ?? [];
  }

  async roll() {
    const rollsRequestArray: SingleDiceRollRequestData[] = [];
    let failure = false;
    if (this.rolld6) {
      if (!this.d6AmountControl.valid || !this.d6RollsControl.valid) {
        failure = true;
      }
      // if ()
      rollsRequestArray.push({
        damount: this.d6AmountControl.value,
        drolls: this.d6RollsControl.value,
        diceType: 6,
      });
    }
    if (
      this.rolld20 &&
      this.d20AmountControl.valid &&
      this.d20RollsControl.valid
    ) {
      if (!this.d20AmountControl.valid || !this.d20RollsControl.valid) {
        failure = true;
      }
      rollsRequestArray.push({
        damount: this.d20AmountControl.value,
        drolls: this.d20RollsControl.value,
        diceType: 20,
        modifier: this.d20modifier,
      });
    }

    if (failure) {
      const toast = await this.toastCtrl.create({
        message: 'Invalid input data for roll',
        color: 'danger',
        position: 'top',
        duration: 3000,
      });
      await toast.present();
      return;
    }

    if (rollsRequestArray.length > 0) {
      const thisRollData: PreviousRoll = {
        rolld6: this.rolld6,
        amountd6: this.d6AmountControl.value,
        throwsd6: this.d6RollsControl.value,
        rolld20: this.rolld20,
        amountd20: this.d20AmountControl.value,
        throwsd20: this.d20RollsControl.value,
        modd20: this.d20modifier,
      };

      if (
        !this.previousRolls.some(
          (x) => JSON.stringify(x) === JSON.stringify(thisRollData)
        )
      ) {
        if (this.previousRolls.length >= 5) {
          this.previousRolls.shift();
        }

        this.previousRolls.push(thisRollData);
      }

      // const previousRolls = this.loca
      this.localStorageService.set('previous-rolls', this.previousRolls);
      this.throttler$.next(rollsRequestArray);
    }
  }

  setPreviousRoll(previousRoll: PreviousRoll) {
    this.rolld6 = previousRoll.rolld6;
    this.rolld20 = previousRoll.rolld20;
    this.d20modifier = previousRoll.modd20;
    this.d6AmountControl.setValue(previousRoll.amountd6);
    this.d20AmountControl.setValue(previousRoll.amountd20);
    this.d6RollsControl.setValue(previousRoll.throwsd6);
    this.d20RollsControl.setValue(previousRoll.throwsd20);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

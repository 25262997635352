import { Injectable } from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { Animation } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class ModalAnimationsCreatorService {
  constructor(private animationCtrl: AnimationController) {}
  createEnterAnimation(): (baseEl: HTMLElement) => Animation {
    return (baseEl: HTMLElement) => {
      const root = baseEl.shadowRoot;

      const backdropAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector('ion-backdrop')!)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector('.modal-wrapper')!)
        .keyframes([
          {
            // offset: 0,
            opacity: '0',
            transform: 'translate3d(0, 0, 0)',
            // transform: 'scale(0)',
          },
          {
            // offset: 1,
            opacity: '0.99',
            transform: 'translate3d(0, 0, 0)',
            // transform: 'scale(1)',
          },
        ]);

      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(200)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };
  }

  createLeaveAnimation(): (baseEl: HTMLElement) => Animation {
    return (baseEl: HTMLElement) => {
      return this.createEnterAnimation()(baseEl).direction('reverse');
    };
  }
}

<ion-fab
  slot="fixed"
  vertical="bottom"
  [edge]="true"
  *ngIf="!!(gameDetails.activeGameProjectedState$$ | async)?.id"
>
  <!-- <ion-select
    style="background: white"
    placeholder="select font"
    (click)="$event.stopPropagation()"
    (ionChange)="changeFont($event)"
    interface="popover"
  >
    <ion-select-option>Mom</ion-select-option>
    <ion-select-option>veteran typewriter</ion-select-option>
  </ion-select> -->
  <ion-fab-button (click)="openDiceRoller()">
    <!-- <ion-icon name="chevron-up-circle"></ion-icon> -->
    <ion-icon name="dice-outline"></ion-icon>
  </ion-fab-button>

  <!-- <ion-fab-list side="top">
    <ion-fab-button>
      <ion-icon name="dice-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab-list> -->
</ion-fab>

import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable, shareReplay } from 'rxjs';
import Ability, {
  AbilityPopulated,
} from 'src/be-models/interfaces/abilities/ability.interface';
import { AbilitiesService } from '../services/abilities-service/abilities.service';

@Injectable({
  providedIn: 'root',
})
export class AbilitiesStoreService {
  public abilities$ = this.abilitiesService
    .getAllAbilities()
    .pipe(shareReplay(1));
  public abilitiesPopulated$: Observable<AbilityPopulated[]> = this.abilities$
    .pipe(
      map((abilitiesUnpopulated) =>
        abilitiesUnpopulated.map((ability) => ({
          ...ability,
          prerequisiteAbilities: ability.prerequisiteAbilities.map(
            (abilityId) => this.populateAbility(abilityId, abilitiesUnpopulated)
          ),
        }))
      )
    )
    .pipe(shareReplay(1));

  get getAbilities() {
    return lastValueFrom(this.abilities$);
  }

  get getAbilitiesPopulated() {
    return lastValueFrom(this.abilitiesPopulated$);
  }
  constructor(private abilitiesService: AbilitiesService) {}

  private populateAbility(
    abilityId: string,
    allAbilities: Ability[]
  ): AbilityPopulated {
    const abilityFound = allAbilities.find((x) => x.id === abilityId);
    if (abilityFound === undefined) {
      console.error(abilityFound);
      throw Error('ability not found: ' + abilityId);
    }
    return {
      ...abilityFound,
      prerequisiteAbilities: abilityFound?.prerequisiteAbilities.map((x) =>
        this.populateAbility(x, allAbilities)
      ),
    };
  }
}

<ion-header draggable="true">
  <ion-toolbar>
    <ion-title>{{modalTitle}}</ion-title>
    <ion-buttons slot="end">
      <ion-button [color]="'light'" (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content [class.default-buttons]="defaultButtons">
  <ng-content></ng-content>
</ion-content>
<ion-footer *ngIf="defaultButtons">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button [color]="'light'" (click)="closeModal()">Cancel</ion-button>
    </ion-buttons>
    <ion-title *ngIf="bottomLabel">
      {{bottomLabel}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button [color]="'light'" (click)="confirm.emit()" [strong]="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
  MsalBroadcastService,
} from '@azure/msal-angular';
import {
  RedirectRequest,
  PopupRequest,
  InteractionType,
  AuthenticationResult,
  AccountInfo,
  EventMessage,
  EventType,
  InteractionStatus,
  PromptValue,
  SsoSilentRequest,
  IdTokenClaims,
} from '@azure/msal-browser';
import {
  BehaviorSubject,
  firstValueFrom,
  lastValueFrom,
  Observable,
  of,
  pipe,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { PartialAuthSession } from 'src/be-models/interfaces/auth/auth-session.interface';
import { environment } from 'src/environments/environment';
import { OnlineSessionService } from '../online-session-service/online-session.service';

export let LoggedUserName: string; //PartialAuthSession;

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // tokenRequested$: Subject<void> = new Subject<void>();
  // // tokenInProgress$: Subject<string>
  // // tokenSelector$;

  // tokenObservable$ = new Observable((subscriber)=> {

  // })

  // private _token$: ReplaySubject<string> = new ReplaySubject<string>(1);
  // tokenSelector$: Subject<string> = new Subject<string>();

  oldToken: string = undefined;

  token$: Observable<string>;

  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tokenExchanged$: Subject<any> = new Subject<any>();

  private readonly _destroying$ = new Subject<void>();

  tokenPipe = pipe(
    switchMap((x) => {
      {
        const activeAccount = this.msalAuthService.instance.getActiveAccount();
        if (activeAccount.idTokenClaims.exp * 1000 > Date.now()) {
          return of({ account: { idToken: activeAccount.idToken } });
        } else {
          let signUpSignInFlowRequest: SsoSilentRequest = {
            authority:
              environment.b2cPolicies.authorities.signUpSignIn.authority,
            account: activeAccount,
          };

          // silently login again with the signUpSignIn policy
          return this.msalAuthService.ssoSilent(signUpSignInFlowRequest);
        }
      }
    }),
    map((x) => x.account.idToken)
  );

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.token$ = new Observable<string>((subscriber) => {
      const activeAccount = this.msalAuthService.instance.getActiveAccount();

      if (activeAccount?.idTokenClaims.exp * 1000 > Date.now()) {
        // Token is still valid, emit the token
        subscriber.next(activeAccount.idToken);
        subscriber.complete(); // Complete the observable after emitting the token
      } else {
        // Token expired, silently login again
        let signUpSignInFlowRequest: SsoSilentRequest = {
          authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
          account: activeAccount,
        };

        this.msalAuthService.ssoSilent(signUpSignInFlowRequest).subscribe({
          next: (result) => {
            subscriber.next(result.idToken); // Emit the new token
            subscriber.complete(); // Complete the observable
          },
          error: (err) => {
            console.log(`here, error, trying to acquireToken with redirect. Be careful if we shouldnt 
             clean the session with onlineSocket.disconnectCleanupAndNavigate();`);
            // ALSO, maybe we should handle unauth (which will happen on socket io) with some better event handling on the BE ? kicking out of the game/logging out?
            this.msalAuthService.acquireTokenRedirect({
              authority:
                environment.b2cPolicies.authorities.signUpSignIn.authority,
              scopes: [...environment.apiConfig.scopes],
            });

            subscriber.error(err); // Handle errors by passing them to the subscriber
          },
        });
      }
    });

    // this.token$.pipe(debounceTime(5000), switchMap(x=> {
    //   {
    //     const activeAccount = this.msalAuthService.instance.getActiveAccount();
    //     if (activeAccount.idTokenClaims.exp * 1000 > Date.now()) {
    //       return of({account: {idToken: activeAccount.idToken}});
    //     } else {
    //       let signUpSignInFlowRequest: SsoSilentRequest = {
    //         authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
    //         account: activeAccount,
    //       };
    //       // silently login again with the signUpSignIn policy
    //       return this.msalAuthService.ssoSilent(signUpSignInFlowRequest);
    //     }
    //   }
    // }), map(x=> x.account.idToken), takeUntil(this._destroying$)).subscribe(x=> {
    //   this._token$.next(x);
    // });
    // this.token$ = this._token$.asObservable();
    // setInterval(
    //   () =>
    //     console.log(this.msalAuthService.instance.getActiveAccount().idToken),
    //   20000
    // );
  }

  async init() {
    console.log('msalGuardConfig', this.msalGuardConfig);
    console.log('msalBroadcastService', this.msalBroadcastService);
    console.log('authSevice', this.msalAuthService);
    this.msalAuthService
      .handleRedirectObservable()
      .pipe(takeUntil(this._destroying$))
      .subscribe((x) => {
        console.log(x);
      });
    this.setLoginDisplay();

    await firstValueFrom(this.msalAuthService.initialize());

    this.msalAuthService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        console.log('test3', result);
        if (this.msalAuthService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        console.log('test1');
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        console.log('test2', result);
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (
          idtoken.acr === environment.b2cPolicies.names.signUpSignIn ||
          idtoken.tfp === environment.b2cPolicies.names.signUpSignIn
        ) {
          console.log(payload.account, payload.accessToken);
          this.msalAuthService.instance.setActiveAccount(payload.account);
        }

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          idtoken.acr === environment.b2cPolicies.names.editProfile ||
          idtoken.tfp === environment.b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.msalAuthService.instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims?.oid === idtoken.oid &&
                account.idTokenClaims?.sub === idtoken.sub &&
                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
                  environment.b2cPolicies.names.signUpSignIn ||
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
                    environment.b2cPolicies.names.signUpSignIn)
            );

          let signUpSignInFlowRequest: SsoSilentRequest = {
            authority:
              environment.b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          await firstValueFrom(
            this.msalAuthService.ssoSilent(signUpSignInFlowRequest)
          );
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (
          idtoken.acr === environment.b2cPolicies.names.resetPassword ||
          idtoken.tfp === environment.b2cPolicies.names.resetPassword
        ) {
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority:
              environment.b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [...environment.apiConfig.scopes],
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
          };

          await this.login(signUpSignInFlowRequest);
        }

        LoggedUserName = this.msalAuthService.instance.getActiveAccount()?.name;

        // this.msalAuthService.instance.getActiveAccount().name;

        const tokenRefreshed =
          this.msalAuthService.instance.getActiveAccount().idToken;
        if (tokenRefreshed !== this.oldToken) {
          this.tokenExchanged$.next(
            this.msalAuthService.instance.getActiveAccount().idToken
          );
        }

        this.oldToken = tokenRefreshed;
        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        //
        if (result.error && result.error.message.indexOf('AADB2C90091') > -1) {
          window.location.reload();
        }

        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority:
              environment.b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        }
      });
  }

  setLoginDisplay() {
    this.isLoggedIn$.next(
      this.msalAuthService.instance.getAllAccounts().length > 0
    );
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalAuthService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalAuthService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
    }
  }

  async loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      await firstValueFrom(
        this.msalAuthService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest)
      );
    } else {
      await firstValueFrom(this.msalAuthService.loginRedirect());
    }
  }

  // TODO: typings
  async login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalAuthService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalAuthService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalAuthService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalAuthService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        await firstValueFrom(
          this.msalAuthService.loginRedirect({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as RedirectRequest)
        );
      } else {
        await firstValueFrom(
          this.msalAuthService.loginRedirect(userFlowRequest)
        );
      }
    }
  }

  async logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalAuthService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalAuthService.logoutRedirect();
    }
  }

  editProfile() {
    let editProfileFlowRequest: RedirectRequest | PopupRequest = {
      authority: environment.b2cPolicies.authorities.editProfile.authority,
      scopes: [],
    };

    this.login(editProfileFlowRequest);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  // signup(data: { name: string; password: string }): Observable<any> {
  //   return this.httpClient.post(HttpUtils.getAuthUrl() + '/signup', data).pipe(
  //     tap((x) => {
  //       if (x.success) {
  //         this.setSession(x.result);
  //       } else {
  //         throw new Error(x.error);
  //       }
  //     })
  //   );
  // }

  // logout() {
  //   return this.httpClient.post(HttpUtils.getAuthUrl() + '/logout', {}).pipe(
  //     tap(() => {
  //       this.removeSessionData();
  //     })
  //   );
  // }

  // refreshToken(token: string) {
  //   return this.httpClient.post(HttpUtils.getAuthUrl() + '/refreshToken', {
  //     token,
  //   });
  // }

  // isLoggedIn() {
  //   return (
  //     Date.now() <
  //     Number(
  //       this.localStorageService.get('expires')
  //       // localStorage.getItem('expires')
  //     )
  //   );
  // }

  // isLoggedOut() {
  //   return !this.isLoggedIn();
  // }

  // removeSessionData() {
  //   this.localStorageService.remove('token');
  //   this.localStorageService.remove('expires');
  //   this.localStorageService.remove('refreshToken');

  //   // localStorage.removeItem('token');
  //   // localStorage.removeItem('expires');
  //   // localStorage.removeItem('refreshToken');
  // }

  // assignUserDetails() {
  //   if (
  //     !this.localStorageService.get('token') ||
  //     this.localStorageService.get('token').split('.').length < 2

  //     // !localStorage.getItem('token') ||
  //     // localStorage.getItem('token').split('.').length < 2
  //   ) {
  //     return;
  //   }

  //   UserDetails = JSON.parse(
  //     atob(
  //       this.localStorageService.get('token').split('.')[1]
  //       // localStorage.getItem('token').split('.')[1]
  //     )
  //   ) as PartialAuthSession;
  // }

  // private setSession(authResult) {
  //   this.localStorageService.set('token', authResult.token);
  //   this.localStorageService.set('expires', authResult.expires);
  //   this.localStorageService.set('refreshToken', authResult.refreshToken);

  //   // localStorage.setItem('token', authResult.token);
  //   // localStorage.setItem('expires', authResult.expires);
  //   // localStorage.setItem('refreshToken', authResult.refreshToken);
  //   this.assignUserDetails();
  // }
}

import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
  lastValueFrom,
} from 'rxjs';
import { distinctUntilKeyChanged, map, shareReplay } from 'rxjs/operators';
import {
  MonsterDefinition,
  MonsterDefinitionPopulated,
} from 'src/be-models/interfaces/monster-definitions/monster-definition';
import { environment } from 'src/environments/environment';
import { MonsterDefinitionsService } from '../services/monster-definitions-service/monster-definitions.service';
import { AbilitiesStoreService } from './abilities.store';

// TODO: IMPORTANT: rework to use PWA cached default items (f.ex. for searching by types)
@Injectable({
  providedIn: 'root',
})
export class MonsterDefinitionsStoreService {
  monsterDefinitions$: Observable<MonsterDefinition[]> =
    this.monsterDefinitionsService
      .getAllDefaultMonsterDefinitions()
      .pipe(shareReplay(1));

  monsterDefinitionsPopulated$: Observable<MonsterDefinitionPopulated[]> =
    combineLatest([
      this.abilitiesStore.abilitiesPopulated$,
      this.monsterDefinitions$,
    ]).pipe(
      map(([ab, bg]) =>
        bg.map((y) => ({
          ...y,
          abilities: y.abilities.map((id) => ab.find((z) => z.id === id)),
        }))
      )
    );

  get getMonsterDefinitions() {
    return lastValueFrom(this.monsterDefinitions$);
  }

  get getMonsterDefinitionsPopulated() {
    return lastValueFrom(this.monsterDefinitionsPopulated$);
  }
  constructor(
    private monsterDefinitionsService: MonsterDefinitionsService,
    private abilitiesStore: AbilitiesStoreService
  ) {}
}

import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-auto-positioning-info-popover',
  templateUrl: './auto-positioning-info-popover.component.html',
  styleUrls: ['./auto-positioning-info-popover.component.scss'],
})
export class AutoPositioningInfoPopoverComponent implements OnInit {
  @Input() text: string;

  ngOnInit() {}
}

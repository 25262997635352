<ion-content>
  <ion-list>
    <ng-container *ngFor="let option of options">
      <!-- <div >NESTED</div> -->
      <ion-item *ngIf="option.nestedOptions" [id]="option.actionId+'trigger'" [button]="true" [detail]="true">{{option.title}}</ion-item>
      <ion-item *ngIf="!option.nestedOptions" [button]="true" [detail]="false" 
        (click)="optionSelected($event, option)">
        <ion-label>{{option.title}}</ion-label>
        <ion-input type="number" class="multi-use-input" *ngIf="option?.usesLeft" [value]="null" 
          (ionBlur)="inputBlur($event, option)" (click)="$event.stopPropagation()" (keyup.enter)="resolveEnterClick($event, option)" 
          onkeydown="return event.keyCode !== 69" [max]="option.usesLeft"></ion-input>
      </ion-item>
      <!-- <ion-item></ion-item> -->
      
    </ng-container>
    <!-- <ion-item [button]="true" [detail]="false">Option 2</ion-item>
    <ion-item [button]="true" id="nested-trigger">More options...</ion-item> -->

    <ng-container *ngFor="let parentOption of options">
      <ng-container *ngIf="parentOption.nestedOptions?.length > 0">
        <ion-popover trigger="{{parentOption.actionId+'trigger'}}" [dismissOnSelect]="true" side="end">
          <ng-template>
            <ion-content>
              <ion-list>
                <ion-item *ngFor="let nestedOption of parentOption.nestedOptions" [button]="true" [detail]="false" 
                  (click)="optionSelected($event, nestedOption, parentOption)">{{nestedOption.title}}</ion-item>
              </ion-list>
            </ion-content>
          </ng-template>
        </ion-popover>
      </ng-container>
    </ng-container>
    
  </ion-list>
</ion-content>

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { EditableRowComponent } from '../../components/table/editable-row/editable-row.component';

export interface MenuOption<T = any> {
  title: string;
  actionId: string;
  parentOptionId?: string;
  nestedOptions?: MenuOption[];
  usesLeft?: number;
  returnData?: T;
}

export interface RowOptionSelected {
  actionDetails: MenuOption;
  rowComponent?: EditableRowComponent;
}

@Component({
  selector: 'app-context-menu-popover',
  templateUrl: './context-menu-popover.component.html',
  styleUrls: ['./context-menu-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuPopoverComponent implements OnInit {
  @Input() options: MenuOption[] = [];

  constructor(private popoverController: PopoverController) {}

  ngOnInit() {
    this.options.forEach((x) => (x.returnData = undefined));
  }

  optionSelected(event: Event, option: MenuOption, parentOption?: MenuOption) {
    if (option?.usesLeft && isNaN(option.returnData)) {
      event.stopPropagation();
      return;
    }
    this.popoverController.dismiss({
      ...option,
      ...(parentOption !== undefined
        ? { parentOptionId: parentOption.actionId }
        : {}),
    });
  }

  inputBlur(event, option: MenuOption) {
    const inputValue = Number(event.target.value);
    option.returnData = Math.min(
      Math.max(inputValue, 0),
      Number(option.usesLeft)
    );
  }

  resolveEnterClick(event, option: MenuOption) {
    const inputValue = Number(event.target.value);
    option.returnData = Math.min(
      Math.max(inputValue, 0),
      Number(option.usesLeft)
    );
    this.optionSelected(event, option);
  }
}

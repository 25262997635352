import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MonsterDefinition } from 'src/be-models/interfaces/monster-definitions/monster-definition';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root',
})
export class MonsterDefinitionsService {
  constructor(private http: HttpService) {}

  getAllDefaultMonsterDefinitions(): Observable<MonsterDefinition[]> {
    return this.http.get('/monster-definitions');
  }
}

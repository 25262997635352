import { firstValueFrom, lastValueFrom, withLatestFrom } from 'rxjs';
import GlobalUtils from 'src/app/utils/global-utils';
import { AbilityExchangeCombination } from 'src/be-models/enums/ability-exchange-combination';
import { Attributes } from 'src/be-models/interfaces/characters/attributes';
import {
  BaseCreature,
  CreaturePopulated,
} from 'src/be-models/interfaces/characters/base-creature';
import { CharacterPopulated } from 'src/be-models/interfaces/characters/character';
import {
  CustomMonsterAbility,
  Monster,
  MonsterPopulated,
} from 'src/be-models/interfaces/characters/monster';
import { NPCPopulated } from 'src/be-models/interfaces/characters/npc';
import Condition from 'src/be-models/interfaces/conditions/condition';
import ConsumableItemType from 'src/be-models/interfaces/item-definitions/types/consumable-type.interface';
import { NoteWithoutContent } from 'src/be-models/interfaces/notes/note';
import { AbilitiesStoreService } from '../../api/stores/abilities.store';
import { BackgroundStoreService } from '../../api/stores/backgrounds.store';
import { ConditionsStoreService } from '../../api/stores/conditions.store';
import { MonsterDefinitionsStoreService } from '../../api/stores/monster-definitions.store';
import { TraitStoreService } from '../../api/stores/traits.store';
import {
  NewManualPropertyValuePair,
  CharDetailsAbilitiesUpdatedAction,
  CharDetailsAction,
  CharDetailsActionType,
  CharDetailsCharCreatedAction,
  CharDetailsConditionsUpdatedAction,
  CharDetailsDayPassedAction,
  CharDetailsItemEatenAction,
  CharDetailsLevelUpAction,
  CharDetailsManualValueUpdatedUpdateAction,
  CharDetailsNextLevelAllowedAction,
  CharDetailsAssignToHideoutAction,
  CharDetailsUnassignFromHideoutAction,
  CharDetailsUnassignFromFacilityAction,
  CharDetailsCreateNoteAction,
  CharDetailsDeleteNoteAction,
  CharDetailsChangeNoteTitleAction,
  CharDetailsCustomConditionsUpdatedAction,
  ConditionIdWithAmount,
  CharDetailsNPCDescriptionUpdatedAction,
  CharDetailsNPCLevelUpdatedAction,
  CharDetailsCustomAbilityUpdatedAction,
  CharDetailsGroupUpdatedAction,
  CharDetailsCharRemovedRequested,
  CharDetailsCharRemovedFinalized,
  CharDetailsUpdatePatrons,
  CharDetailsCharCreationFinalized,
  CharDetailsAssingToFacilityAction,
} from './game-character-actions';
import { CharactersUtils } from './game-characters-utils';

export class CharDetailsSingleActionRegistryResolver {
  constructor(
    private traitsStore: TraitStoreService,
    private backgroundsStore: BackgroundStoreService,
    private conditionStore: ConditionsStoreService,
    private abilitiesStore: AbilitiesStoreService,
    private monsterDefsStore: MonsterDefinitionsStoreService
  ) {}

  public async resolveCharDetailsSingleActionRegistry(
    action: CharDetailsAction,
    charactersRegistryArray: CreaturePopulated[],
    timestamp: number
  ): Promise<CreaturePopulated[]> {
    console.log('container bulkOperationResolver', action);

    const characterInRegistry = charactersRegistryArray.find(
      (x) => x.id === action.payload.id
    );

    switch (action.actionType) {
      case CharDetailsActionType.ItemEaten: {
        const itemEatenAction = action as CharDetailsItemEatenAction;

        charactersRegistryArray = this.itemEatenStatsUpdate(
          itemEatenAction.payload.id,
          itemEatenAction.itemEatenDetails,
          charactersRegistryArray
        );

        // charactersRegistryArray = this.itemEatenActiveEffectsUpdate(
        //   dietUpdateAction.charDetailsPayload.id,
        //   charactersRegistryArray
        // );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   itemEatenAction.charDetailsPayload.id,
        //   timestamp
        // );
        break;
      }

      case CharDetailsActionType.ConditionsUpdated: {
        const conditionsUpdatedActions =
          action as CharDetailsConditionsUpdatedAction;

        charactersRegistryArray = await this.conditionsUpdated(
          conditionsUpdatedActions.payload.id,
          conditionsUpdatedActions.conditionsToAdd,
          conditionsUpdatedActions.conditionsToRemove,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   conditionsUpdatedActions.charDetailsPayload.id,
        //   timestamp
        // );
        break;
      }

      case CharDetailsActionType.CustomConditionsUpdated: {
        const customConditionsUpdatedActions =
          action as CharDetailsCustomConditionsUpdatedAction;

        charactersRegistryArray = await this.customConditionsUpdated(
          customConditionsUpdatedActions.payload.id,
          customConditionsUpdatedActions.conditionsToAdd,
          customConditionsUpdatedActions.conditionsToRemove,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   customConditionsUpdatedActions.charDetailsPayload.id,
        //   timestamp
        // );
        break;
      }

      case CharDetailsActionType.ManualValueUpdated: {
        const manualValueUpdatedAction =
          action as CharDetailsManualValueUpdatedUpdateAction;

        charactersRegistryArray = this.updateManualValue(
          manualValueUpdatedAction.payload.id,
          manualValueUpdatedAction.characterStatsManualValueUpdatedDetails,
          charactersRegistryArray
        );
        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   manualValueUpdatedAction.charDetailsPayload.id,
        //   timestamp
        // );

        break;
      }

      case CharDetailsActionType.DayPassedUpdate: {
        const dayPassedAction = action as CharDetailsDayPassedAction;
        charactersRegistryArray = this.dayPassedCharUpdates(
          dayPassedAction.payload.id,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   dayPassedAction.charDetailsPayload.id,
        //   timestamp
        // );

        break;
      }

      case CharDetailsActionType.NextLevelAllowed: {
        const newLevelAllowedAction =
          action as CharDetailsNextLevelAllowedAction;
        if (
          characterInRegistry &&
          CharactersUtils.isPlayerCharacter(characterInRegistry)
        ) {
          characterInRegistry.levelUpAvailable =
            !newLevelAllowedAction.disallow;
          // this.updateTimestamp(
          //   charactersRegistryArray,
          //   newLevelAllowedAction.charDetailsPayload.id,
          //   timestamp
          // );
        }
        break;
      }

      case CharDetailsActionType.LevelUp: {
        const levelUpdateAction = action as CharDetailsLevelUpAction;

        charactersRegistryArray = await this.levelUpCharacter(
          levelUpdateAction.payload.id,
          levelUpdateAction.updatedAttributes,
          levelUpdateAction.abilitiesChosenIds,
          charactersRegistryArray,
          levelUpdateAction.abilityExchange
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   levelUpdateAction.charDetailsPayload.id,
        //   timestamp
        // );

        break;
      }
      case CharDetailsActionType.CharCreated: {
        const charCreatedAction = action as CharDetailsCharCreatedAction;

        // cannot do plain push because of simultaneous connection to game on game creation - resulting, from point of
        // view of creator - in duplicated character in registry.

        charactersRegistryArray = await this.upsertCharDetailsToRegistry(
          charCreatedAction.newCharacter,
          charactersRegistryArray
        );

        break;
      }

      case CharDetailsActionType.AbilitiesUpdated: {
        const abilitiesUpdatedActions =
          action as CharDetailsAbilitiesUpdatedAction;

        charactersRegistryArray = await this.abilitiesUpdated(
          abilitiesUpdatedActions.payload.id,
          abilitiesUpdatedActions.abilitiesToAddIds,
          abilitiesUpdatedActions.abilitiesToRemoveIds,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   abilitiesUpdatedActions.charDetailsPayload.id,
        //   timestamp
        // );
        break;
      }

      case CharDetailsActionType.AssignNpcToHideout: {
        const assignNpcToHideoutAction =
          action as CharDetailsAssignToHideoutAction;
        if (
          characterInRegistry &&
          CharactersUtils.isNPCCharacter(characterInRegistry)
        ) {
          characterInRegistry.parentHideoutId =
            assignNpcToHideoutAction.targetHideoutId;

          // this.updateTimestamp(
          //   charactersRegistryArray,
          //   assignNpcToHideoutAction.charDetailsPayload.id,
          //   timestamp
          // );
        }

        break;
      }

      case CharDetailsActionType.UnassignNpcFromHideout: {
        const unassignNpcFromHideoutAction =
          action as CharDetailsUnassignFromHideoutAction;
        if (
          characterInRegistry &&
          CharactersUtils.isNPCCharacter(characterInRegistry)
        ) {
          characterInRegistry.parentHideoutId = undefined;

          // this.updateTimestamp(
          //   charactersRegistryArray,
          //   unassignNpcFromHideoutAction.charDetailsPayload.id,
          //   timestamp
          // );
        }
        break;
      }

      case CharDetailsActionType.AssignCharToFacility: {
        const assignCharToFacilityAction =
          action as CharDetailsAssingToFacilityAction;
        if (
          characterInRegistry &&
          (CharactersUtils.isNPCCharacter(characterInRegistry) ||
            CharactersUtils.isPlayerCharacter(characterInRegistry))
        ) {
          characterInRegistry.assignedFacilityId =
            assignCharToFacilityAction.facilityHideoutId;

          // this.updateTimestamp(
          //   charactersRegistryArray,
          //   assignCharToFacilityAction.charDetailsPayload.id,
          //   timestamp
          // );
        }

        break;
      }

      case CharDetailsActionType.UnassignCharFromFacility: {
        const unassignCharFromFacilityAction =
          action as CharDetailsUnassignFromFacilityAction;
        if (
          characterInRegistry &&
          (CharactersUtils.isNPCCharacter(characterInRegistry) ||
            CharactersUtils.isPlayerCharacter(characterInRegistry))
        ) {
          // needed check because it might be that npc was updated meanwhile with other facility
          if (
            characterInRegistry.assignedFacilityId ===
            unassignCharFromFacilityAction.facilityId
          ) {
            characterInRegistry.assignedFacilityId = undefined;
          }
        }

        break;
      }

      case CharDetailsActionType.CreateNote: {
        const createNoteAction = action as CharDetailsCreateNoteAction;
        // if (characterInRegistry) {
        charactersRegistryArray = await this.createNote(
          createNoteAction.payload.id,
          createNoteAction.notePayload,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   createNoteAction.charDetailsPayload.id,
        //   timestamp
        // );
        // }

        break;
      }

      case CharDetailsActionType.DeleteNote: {
        const deleteNoteAction = action as CharDetailsDeleteNoteAction;
        // if (characterInRegistry) {
        charactersRegistryArray = await this.deleteNote(
          deleteNoteAction.payload.id,
          deleteNoteAction.noteToDeleteId,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   deleteNoteAction.charDetailsPayload.id,
        //   timestamp
        // );
        // }

        break;
      }

      case CharDetailsActionType.ChangeNoteTitle: {
        const changeNoteTitleAction =
          action as CharDetailsChangeNoteTitleAction;
        charactersRegistryArray = await this.changeNoteTitle(
          changeNoteTitleAction.payload.id,
          changeNoteTitleAction.noteToChangeId,
          changeNoteTitleAction.newTitle,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   changeNoteTitleAction.charDetailsPayload.id,
        //   timestamp
        // );

        break;
      }

      case CharDetailsActionType.NPCDescriptionUpdated: {
        const descriptionUpdatedAction =
          action as CharDetailsNPCDescriptionUpdatedAction;
        charactersRegistryArray = await this.updateDescription(
          descriptionUpdatedAction.payload.id,
          descriptionUpdatedAction.description,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   descriptionUpdatedAction.charDetailsPayload.id,
        //   timestamp
        // );
        break;
      }

      case CharDetailsActionType.NPCLevelUpdated: {
        const levelUpdatedAction = action as CharDetailsNPCLevelUpdatedAction;
        charactersRegistryArray = await this.changeNPCLevel(
          levelUpdatedAction.payload.id,
          levelUpdatedAction.newLevel,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   levelUpdatedAction.charDetailsPayload.id,
        //   timestamp
        // );
        break;
      }

      case CharDetailsActionType.UpdateCustomAbility: {
        const customAbilityUpdatedAction =
          action as CharDetailsCustomAbilityUpdatedAction;

        charactersRegistryArray = await this.updateCustomAbilities(
          customAbilityUpdatedAction.payload.id,
          customAbilityUpdatedAction.abilitiesToAdd,
          customAbilityUpdatedAction.abilitiesIdToRemove,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   customAbilityUpdatedAction.charDetailsPayload.id,
        //   timestamp
        // );
        break;
      }

      case CharDetailsActionType.ChangeGroupId: {
        const changeGroupIdAction = action as CharDetailsGroupUpdatedAction;

        charactersRegistryArray = await this.updateGroupId(
          changeGroupIdAction.payload.id,
          changeGroupIdAction.newGroupId,
          charactersRegistryArray
        );

        // this.updateTimestamp(
        //   charactersRegistryArray,
        //   changeGroupIdAction.charDetailsPayload.id,
        //   timestamp
        // );
        break;
      }

      // TODO: Two part operation because of other operations in-between. If need for more operations like this,
      // add some flag or property for "stateless" operations. OR move them to generic operations/separate store?
      case CharDetailsActionType.CharRemovalRequested: {
        console.log('CharRemovalOrdered');

        // charactersRegistryArray = this.removeCharacter(
        //   npcRemovedAction.charDetailsPayload.id,
        //   charactersRegistryArray
        // );

        // if (npcMonsterGroupChangedAction.creatureToAddDetails?.id) {

        break;
      }

      case CharDetailsActionType.CharRemovalFinalized: {
        const npcRemovedAction = action as CharDetailsCharRemovedFinalized;

        charactersRegistryArray = this.removeCharacter(
          npcRemovedAction.payload.id,
          charactersRegistryArray
        );
        break;
      }

      case CharDetailsActionType.CharCreationRequested: {
        console.log('char creation ordered');
        break;
      }

      case CharDetailsActionType.CharCreationFinalized: {
        const charCreatedAction = action as CharDetailsCharCreationFinalized;
        charactersRegistryArray = await this.upsertCharDetailsToRegistry(
          charCreatedAction.newCharacter,
          charactersRegistryArray
        );
        break;
      }

      case CharDetailsActionType.UpdatePatrons: {
        const updatePatronsAction = action as CharDetailsUpdatePatrons;

        if (
          characterInRegistry &&
          CharactersUtils.isPlayerCharacter(characterInRegistry)
        ) {
          characterInRegistry.patronsInfo = updatePatronsAction.patrons;
        }

        break;
      }

      default:
        break;
    }

    if (
      characterInRegistry &&
      !action.isSecret &&
      ![
        // CharDetailsActionType.CharCreated,
        CharDetailsActionType.CharRemovalFinalized,
      ].includes(action.actionType)
    ) {
      this.updateTimestamp(
        charactersRegistryArray,
        characterInRegistry.id,
        timestamp
      );
    }

    return charactersRegistryArray;
  }

  private updateTimestamp(
    registryArray: { dateModified?: number; id: string }[],
    itemId: string,
    timestamp: number
  ) {
    const itemInArray = registryArray.find((x) => x.id === itemId);

    // TODO: consider if timestamp should be updated here or within "attemptToResolveAction" specific action places (where it's indeed updated)
    if (itemInArray) {
      itemInArray.dateModified = timestamp;
    }
  }

  private itemEatenStatsUpdate(
    characterId: string,
    itemEaten: Partial<ConsumableItemType>,
    charDetailsArray: CreaturePopulated[]
  ): CreaturePopulated[] {
    const characterInArray = charDetailsArray.find(
      (x) => x.id === characterId
    ) as CharacterPopulated;
    if (characterInArray?.dietInfo?.history?.[0]) {
      characterInArray.dietInfo.history[0].hydrationAcc += itemEaten.hydration;
      characterInArray.dietInfo.history[0].nutritionAcc += itemEaten.nutrition;
      characterInArray.dietInfo.history[0].dietCategoriesAcc.push(
        ...itemEaten.foodCategory.filter(
          (itemsFoodCategory) =>
            !characterInArray.dietInfo.history[0].dietCategoriesAcc.includes(
              itemsFoodCategory
            )
        )
      );
    }
    return charDetailsArray;
  }

  private async conditionsUpdated(
    characterId: string,
    conditionsToAdd: ConditionIdWithAmount[],
    conditionsToRemove: ConditionIdWithAmount[],
    charDetailsArray: CreaturePopulated[]
  ): Promise<CreaturePopulated[]> {
    const characterInArray = charDetailsArray.find((x) => x.id === characterId);
    const conditionsPopulated = await lastValueFrom(
      this.conditionStore.conditions$
    );

    if (characterInArray) {
      characterInArray.activeConditions =
        characterInArray.activeConditions ?? [];
      if (conditionsToAdd) {
        const conditionsToAddPopulated = conditionsPopulated.filter((x) =>
          conditionsToAdd.map((y) => y.id).includes(x.id)
        );

        if (conditionsToAddPopulated.length !== conditionsToAdd.length) {
          console.error(
            'conditions populated differs from conditions to add',
            conditionsToAdd,
            conditionsToAddPopulated
          );
        }

        for (let conditionToBeAdded of conditionsToAddPopulated) {
          const conditionAlreadyPresentIndex =
            characterInArray.activeConditions.findIndex(
              (x) => x.condition.id === conditionToBeAdded.id
            );
          const amountOfStacks = conditionsToAdd.find(
            (x) => x.id === conditionToBeAdded.id
          ).amountOfStacks;
          if (conditionAlreadyPresentIndex >= 0) {
            // Add to stacks of present condition (for cases when "amountOfStacks" applied)
            // const currentConditionStacksAmount =
            //   characterInArray.activeConditions[conditionAlreadyPresentIndex]
            //     .amountOfStacks;
            characterInArray.activeConditions[conditionAlreadyPresentIndex] = {
              condition: conditionToBeAdded,
              amountOfStacks,
            };
          } else {
            characterInArray.activeConditions.push({
              condition: conditionToBeAdded,
              amountOfStacks,
            });
          }
        }
      }
      if (conditionsToRemove) {
        characterInArray.activeConditions =
          characterInArray.activeConditions.filter(
            (x) => !conditionsToRemove.map((y) => y.id).includes(x.condition.id)
          );
      }
    }
    return charDetailsArray;
  }

  private async customConditionsUpdated(
    characterId: string,
    conditionsToAdd: Condition[],
    conditionsToRemove: string[],
    charDetailsArray: CreaturePopulated[]
  ): Promise<CreaturePopulated[]> {
    const characterInArray = charDetailsArray.find((x) => x.id === characterId);

    if (characterInArray) {
      characterInArray.activeCustomConditions =
        characterInArray.activeCustomConditions ?? [];
      if (conditionsToAdd) {
        for (let conditionToBeAdded of conditionsToAdd) {
          characterInArray.activeCustomConditions.push(conditionToBeAdded);
        }
      }
      if (conditionsToRemove) {
        characterInArray.activeCustomConditions =
          characterInArray.activeCustomConditions.filter(
            (x) => !conditionsToRemove.includes(x.id)
          );
      }
    }
    return charDetailsArray;
  }

  private async abilitiesUpdated(
    characterId: string,
    abilitiesToAddIds: string[],
    abilitiesToRemoveIds: string[],
    charDetailsArray: CreaturePopulated[]
  ): Promise<CreaturePopulated[]> {
    const characterInArray = charDetailsArray.find((x) => x.id === characterId);
    const allAbilities = await lastValueFrom(
      this.abilitiesStore.abilitiesPopulated$
    );

    if (characterInArray) {
      characterInArray.abilities = characterInArray.abilities ?? [];
      if (abilitiesToAddIds) {
        const abilitiesToAddPopulated = allAbilities.filter((x) =>
          abilitiesToAddIds.includes(x.id)
        );
        for (let abilityToBeAdded of abilitiesToAddPopulated) {
          const abilitiesAlreadyPresentIndex =
            CharactersUtils.resolveCreatureJointAbilities(
              characterInArray
            ).findIndex((x) => x.id === abilityToBeAdded.id);
          if (abilitiesAlreadyPresentIndex < 0) {
            characterInArray.abilities.push(abilityToBeAdded);
          }
        }
      }
      if (abilitiesToRemoveIds) {
        characterInArray.abilities = characterInArray.abilities.filter(
          (x) => !abilitiesToRemoveIds.includes(x.id)
        );
      }
    }
    return charDetailsArray;
  }

  updateManualValue(
    characterId: string,
    newPropertyValuePair: NewManualPropertyValuePair,
    charDetailsArray: CreaturePopulated[]
  ): CreaturePopulated[] {
    const characterInArray = charDetailsArray.find((x) => x.id === characterId);
    if (characterInArray) {
      GlobalUtils.setDeepValue(
        characterInArray,
        newPropertyValuePair.propertyPath,
        newPropertyValuePair.value
      );
    }
    return charDetailsArray;

    // this.updateSelectedCharacterEditableProperty(propertyPath, x.newValue);
  }

  dayPassedCharUpdates(
    characterId: string,
    charDetailsArray: CreaturePopulated[]
  ): CreaturePopulated[] {
    const characterInArray = charDetailsArray.find(
      (x) => x.id === characterId
    ) as CharacterPopulated;
    const passedDay = characterInArray?.dietInfo?.history?.[0];
    if (passedDay) {
      characterInArray.dietInfo.hydrationDeficit = Math.min(
        characterInArray.dietInfo.hydrationDeficit +
          passedDay.hydrationAcc -
          10,
        0
      );
      characterInArray.dietInfo.nutritionDeficit = Math.min(
        characterInArray.dietInfo.nutritionDeficit +
          passedDay.nutritionAcc -
          10,
        0
      );

      const dietHistoryLength = characterInArray.dietInfo.history.unshift({
        nutritionAcc: 0,
        hydrationAcc: 0,
        dietCategoriesAcc: [],
      });

      if (dietHistoryLength > 3) {
        characterInArray.dietInfo.history =
          characterInArray?.dietInfo?.history.slice(0, 3);
      }
    }
    return charDetailsArray;
  }

  async levelUpCharacter(
    characterId: string,
    updatedAttributes: Attributes,
    newAbilitiesIds: string[],
    charDetailsArray: CreaturePopulated[],
    abilityExchange?: AbilityExchangeCombination
  ): Promise<CreaturePopulated[]> {
    const characterInArray = charDetailsArray.find(
      (x) => x.id === characterId
    ) as CharacterPopulated;

    if (characterInArray) {
      const allAbilities = await lastValueFrom(
        this.abilitiesStore.abilitiesPopulated$
      );

      characterInArray.baseAttributes = updatedAttributes;
      characterInArray.abilities.push(
        ...newAbilitiesIds.map((x) => allAbilities.find((y) => y.id === x))
      );
      characterInArray.levelUpAbilityExchange = abilityExchange;
      characterInArray.level += 1;
      characterInArray.levelUpAvailable = false;
    }

    return charDetailsArray;
  }

  async upsertCharDetailsToRegistry(
    newCharDetails: BaseCreature,
    charDetailsArray: CreaturePopulated[]
  ) {
    const itemIndex = charDetailsArray.findIndex(
      (x) => x.id === newCharDetails.id
    );

    let populatedChar: CreaturePopulated = undefined;
    const backgrounds = await this.backgroundsStore.getBackgroundPopulated;
    const traits = await this.traitsStore.getTraitPopulated;
    const conditions = await this.conditionStore.getConditions;
    const allAbilities = await this.abilitiesStore.getAbilitiesPopulated;
    const monsterDefinitions = await this.monsterDefsStore
      .getMonsterDefinitionsPopulated;

    populatedChar = CharactersUtils.populateCreature(
      newCharDetails,
      allAbilities,
      conditions,
      monsterDefinitions,
      backgrounds,
      traits
    );

    if (itemIndex > -1) {
      charDetailsArray[itemIndex] = populatedChar;
    } else {
      charDetailsArray.push(populatedChar);
    }
    // }
    return charDetailsArray;
  }

  private async createNote(
    characterId: string,
    newNote: NoteWithoutContent,
    charDetailsArray: CreaturePopulated[]
  ) {
    const characterInArray = charDetailsArray.find(
      (x) => x.id === characterId
    ) as CharacterPopulated;

    if (characterInArray) {
      characterInArray.notes.push(newNote);
    }

    return charDetailsArray;
  }

  private async deleteNote(
    characterId: string,
    noteToDeleteId: string,
    charDetailsArray: CreaturePopulated[]
  ) {
    const characterInArray = charDetailsArray.find(
      (x) => x.id === characterId
    ) as CharacterPopulated;

    if (characterInArray) {
      characterInArray.notes.splice(
        characterInArray.notes.findIndex((x) => x.id === noteToDeleteId),
        1
      );
    }

    return charDetailsArray;
  }

  private async changeNoteTitle(
    characterId: string,
    noteId: string,
    newNoteTitle: string,
    charDetailsArray: CreaturePopulated[]
  ) {
    const characterInArray = charDetailsArray.find(
      (x) => x.id === characterId
    ) as CharacterPopulated;

    if (characterInArray) {
      const noteToChange = characterInArray.notes.find((x) => x.id === noteId);
      if (noteToChange) {
        noteToChange.title = newNoteTitle;
      }
    }

    return charDetailsArray;
  }

  private async updateDescription(
    characterId: string,
    description: string,
    charDetailsArray: CreaturePopulated[]
  ) {
    const characterInArray = charDetailsArray.find((x) => x.id === characterId);
    if (
      !characterInArray ||
      (!CharactersUtils.isMonsterCharacter(characterInArray) &&
        !CharactersUtils.isNPCCharacter(characterInArray))
    ) {
      return charDetailsArray;
    }
    characterInArray.description = description;

    return charDetailsArray;
  }

  private async changeNPCLevel(
    characterId: string,
    newLevel: number,
    charDetailsArray: CreaturePopulated[]
  ) {
    const characterInArray = charDetailsArray.find((x) => x.id === characterId);
    if (
      !characterInArray ||
      (!CharactersUtils.isMonsterCharacter(characterInArray) &&
        !CharactersUtils.isNPCCharacter(characterInArray))
    ) {
      return charDetailsArray;
    }
    characterInArray.level = newLevel;

    return charDetailsArray;
  }

  private updateCustomAbilities(
    characterId: string,
    customAbilitiesToAdd: CustomMonsterAbility[] | undefined,
    customAbilitiesToRemoveIds: string[] | undefined,
    charDetailsArray: CreaturePopulated[]
  ): CreaturePopulated[] {
    const characterInArray = charDetailsArray.find((x) => x.id === characterId);
    if (
      !characterInArray ||
      !CharactersUtils.isMonsterCharacter(characterInArray)
    ) {
      return charDetailsArray;
    }

    if (characterInArray) {
      characterInArray.customAbilities = characterInArray.customAbilities ?? [];
      if (customAbilitiesToAdd) {
        for (const abilityToBeAdded of customAbilitiesToAdd) {
          const abilityAlreadyPresentIndex =
            characterInArray.customAbilities.findIndex(
              (x) => x.id === abilityToBeAdded.id
            );
          if (abilityAlreadyPresentIndex < 0) {
            characterInArray.customAbilities.push(abilityToBeAdded);
          } else {
            characterInArray.customAbilities[abilityAlreadyPresentIndex];
          }
        }
      }
      if (customAbilitiesToRemoveIds) {
        characterInArray.customAbilities =
          characterInArray.customAbilities.filter(
            (x) => !customAbilitiesToRemoveIds.some((y) => y === x.id)
          );
      }
    }
    return charDetailsArray;
  }

  private updateGroupId(
    characterId: string,
    newGroupId: string,
    charDetailsArray: CreaturePopulated[]
  ) {
    const characterInArray = charDetailsArray.find((x) => x.id === characterId);
    if (
      !characterInArray ||
      CharactersUtils.isPlayerCharacter(characterInArray)
    ) {
      return charDetailsArray;
    }
    (characterInArray as NPCPopulated | MonsterPopulated).groupId = newGroupId;
    return charDetailsArray;
  }

  private removeCharacter(
    id: string,
    charDetailsArray: CreaturePopulated[]
  ): CreaturePopulated[] {
    charDetailsArray.splice(
      charDetailsArray.findIndex((x) => x.id === id),
      1
    );

    return charDetailsArray;
  }
}

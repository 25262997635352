import { Injectable, Injector } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  AlertController,
  ModalController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { BehaviorSubject, skip } from 'rxjs';
import { DragDropManagerService } from './drag-drop-manager/drag-drop-manager.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  appInjector: Injector;
  reactInitialised: boolean = false;

  // needed to easily cut ties to all drag drop zones from creation part after leaving it;
  createNewOngoing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  currentlyActiveDragDropManager$: BehaviorSubject<DragDropManagerService> =
    new BehaviorSubject<DragDropManagerService>(undefined);

  isNpcMode$ = new BehaviorSubject<boolean>(true);
  loggedSlotId$ = new BehaviorSubject<string>(undefined);

  refreshView$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private router: Router
  ) {
    this.router.events.subscribe((event: any): void => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.closeModals();
          this.closePopovers();
          this.closeAlerts();
        }
      }
    });

    this.refreshView$.pipe(skip(1)).subscribe(() =>
      this.toastCtrl
        .create({
          message:
            'Your action failed because the resource was updated recently. View refreshed.',
          duration: 5000,
          position: 'top',
          color: 'warning',
          buttons: [
            {
              icon: 'close-outline',
              // text: 'Dismiss',
              role: 'cancel',
            },
          ],
        })
        .then((x) => x.present())
    );
  }

  // public setCurrentlyActiveDragDropManager(newInstance: DragDropManagerService) {

  // }

  private async closeGenericModals(
    controller: ModalController | PopoverController | AlertController
  ) {
    let moreModals = true;
    while (moreModals) {
      try {
        const element = await controller.getTop();
        if (element) {
          element.dismiss();
          return;
        } else {
          moreModals = false;
        }
      } catch (error) {
        console.log(error);
        moreModals = false;
      }
    }
  }

  private async closeModals() {
    this.closeGenericModals(this.modalCtrl);
  }

  private async closePopovers() {
    this.closeGenericModals(this.popoverCtrl);
  }

  private async closeAlerts() {
    this.closeGenericModals(this.alertCtrl);
  }
}

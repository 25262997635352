<app-base-modal [modalTitle]="'Dice Roller'">
  <div class="main-wrapper" *ngIf="!(screenSizeService.isMobile$ | async)">
    <ion-grid class="grid">
      <ion-row>
        <ion-col>
          <ion-list>
            <ion-item>
              <ion-label>Roll d6</ion-label>
              <ion-checkbox [(ngModel)]="rolld6"></ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-input
                label="How many dice? (max 60)"
                [formControl]="d6AmountControl"
                placeholder="Type.."
                [min]="0"
                [max]="60"
                onkeydown="return event.keyCode !== 69"
                [disabled]="!rolld6"
                type="number"
                slot="end"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-input
                label="How many times? (max 10)"
                [formControl]="d6RollsControl"
                placeholder="Type.."
                [min]="0"
                [max]="10"
                onkeydown="return event.keyCode !== 69"
                [disabled]="!rolld6"
                type="number"
                slot="end"
              ></ion-input>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
      <div *ngIf="rolld6 && d6AmountControl.errors" class="error">
        Wrong input for d6 amount. Stay in range &lt;1;60&gt;.
      </div>
      <div *ngIf="rolld6 && d6RollsControl.errors" class="error">
        Wrong input for d6 throws. Stay in range &lt;1;10&gt;.
      </div>
      <ion-row>
        <ion-col>
          <ion-list>
            <ion-item>
              <ion-label>Roll d20</ion-label>
              <ion-checkbox [(ngModel)]="rolld20"></ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-input
                label="How many dice? (max 10)"
                [formControl]="d20AmountControl"
                placeholder="Type.."
                [min]="0"
                [max]="10"
                onkeydown="return event.keyCode !== 69"
                [disabled]="!rolld20"
                type="number"
                slot="end"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-input
                label="How many times? (max 10)"
                [formControl]="d20RollsControl"
                type="number"
                slot="end"
                placeholder="Type.."
                [min]="0"
                [max]="10"
                onkeydown="return event.keyCode !== 69"
                [disabled]="!rolld20"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-input
                label="Flat modifier?"
                [min]="0"
                [max]="60"
                type="number"
                slot="end"
                [(ngModel)]="d20modifier"
                [disabled]="!rolld20"
              ></ion-input>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

      <div *ngIf="rolld20 && d20AmountControl.errors" class="error">
        Wrong input for d20 amount. Stay in range &lt;1;10&gt;.
      </div>
      <div *ngIf="rolld20 && d20RollsControl.errors" class="error">
        Wrong input for d20 throws. Stay in range &lt;1;10&gt;.
      </div>
      <ion-row>
        <ion-col class="roll-col">
          <div class="roll-wrapper">
            <ion-item class="roll-item">
              <ion-icon
                style="z-index: 3"
                name="information-circle-outline"
                appAutoPositioningInfoPopover
                [simpleText]="
                  'Whether the roll result will be send to the Game Feed (to the all player chat) or not.'
                "
              >
              </ion-icon>
              <ion-label class="publish-roll-label">Public roll</ion-label>
              <ion-checkbox [(ngModel)]="publishRoll"></ion-checkbox>
            </ion-item>

            <ion-button class="roll-button" (click)="roll()">Roll</ion-button>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="previousRolls?.length > 0" class="previous-rolls">
        <div class="chips-wrapper">
          <ion-chip
            *ngFor="let previousRoll of previousRolls.reverse()"
            (click)="setPreviousRoll(previousRoll)"
          >
            {{
              (previousRoll.rolld6
                ? previousRoll.amountd6 + "d6 x" + previousRoll.throwsd6
                : "") +
                (previousRoll.rolld20
                  ? " | " +
                    previousRoll.amountd20 +
                    "d20" +
                    (previousRoll.modd20 ? "+" + previousRoll.modd20 : "") +
                    " x" +
                    previousRoll.throwsd20
                  : "")
            }}
          </ion-chip>
        </div>
      </ion-row>
    </ion-grid>
    <div class="dices-results">
      <div class="results-title">Results:</div>
      <app-dices-results
        *ngIf="results"
        [results]="results"
      ></app-dices-results>
      <div class="no-results" *ngIf="!results">No results</div>
    </div>
  </div>

  <div class="main-wrapper mobile" *ngIf="screenSizeService.isMobile$ | async">
    <ion-grid class="grid-mobile">
      <ion-row>
        <ion-col> </ion-col>
        <ion-col class="dice-column">
          <ion-item>
            <ion-label>Roll d6</ion-label>
            <ion-checkbox [(ngModel)]="rolld6"></ion-checkbox>
          </ion-item>
        </ion-col>
        <ion-col class="dice-column">
          <ion-item>
            <ion-label>Roll d20</ion-label>
            <ion-checkbox [(ngModel)]="rolld20"></ion-checkbox>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-label> Dice: </ion-label>
        </ion-col>
        <ion-col>
          <ion-input
            [formControl]="d6AmountControl"
            placeholder="Max 60"
            [min]="0"
            [max]="60"
            onkeydown="return event.keyCode !== 69"
            [disabled]="!rolld6"
            type="number"
          ></ion-input>
        </ion-col>
        <ion-col>
          <ion-input
            [formControl]="d20AmountControl"
            placeholder="Max 10"
            [min]="0"
            [max]="10"
            onkeydown="return event.keyCode !== 69"
            [disabled]="!rolld20"
            type="number"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-label> Throws: </ion-label>
        </ion-col>
        <ion-col>
          <ion-input
            [formControl]="d6RollsControl"
            placeholder="Max 10"
            [min]="0"
            [max]="10"
            onkeydown="return event.keyCode !== 69"
            [disabled]="!rolld6"
            type="number"
          ></ion-input>
        </ion-col>
        <ion-col>
          <ion-input
            [formControl]="d20RollsControl"
            type="number"
            placeholder="Max 10"
            [min]="0"
            [max]="10"
            onkeydown="return event.keyCode !== 69"
            [disabled]="!rolld20"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col> F. Mod. </ion-col>
        <ion-col> N/A </ion-col>
        <ion-col>
          <ion-input
            [placeholder]="'Modifier'"
            [min]="0"
            [max]="60"
            type="number"
            [(ngModel)]="d20modifier"
            [disabled]="!rolld20"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="roll-col">
          <div class="roll-wrapper">
            <ion-item class="roll-item">
              <ion-icon
                style="z-index: 3"
                name="information-circle-outline"
                appAutoPositioningInfoPopover
                [simpleText]="
                  'Whether the roll result will be send to the Game Feed (to the all player chat) or not.'
                "
              >
              </ion-icon>
              <ion-label class="publish-roll-label">Public</ion-label>
              <ion-checkbox [(ngModel)]="publishRoll"></ion-checkbox>
            </ion-item>

            <ion-button class="roll-button" (click)="roll()">Roll</ion-button>
          </div>
        </ion-col>
      </ion-row>

      <div *ngIf="rolld6 && d6AmountControl.errors" class="error">
        Wrong input for d6 amount. Stay in range &lt;1;60&gt;.
      </div>
      <div *ngIf="rolld20 && d20AmountControl.errors" class="error">
        Wrong input for d20 amount. Stay in range &lt;1;10&gt;.
      </div>
      <div *ngIf="rolld6 && d6RollsControl.errors" class="error">
        Wrong input for d6 throws. Stay in range &lt;1;10&gt;.
      </div>
      <div *ngIf="rolld20 && d20RollsControl.errors" class="error">
        Wrong input for d20 throws. Stay in range &lt;1;10&gt;.
      </div>

      <ion-row *ngIf="previousRolls?.length > 0" class="previous-rolls mobile">
        <div class="chips-wrapper">
          <ion-chip
            *ngFor="let previousRoll of previousRolls.reverse()"
            (click)="setPreviousRoll(previousRoll)"
          >
            {{
              (previousRoll.rolld6
                ? previousRoll.amountd6 + "d6 x" + previousRoll.throwsd6
                : "") +
                (previousRoll.rolld20
                  ? " | " +
                    previousRoll.amountd20 +
                    "d20" +
                    (previousRoll.modd20 ? "+" + previousRoll.modd20 : "") +
                    " x" +
                    previousRoll.throwsd20
                  : "")
            }}
          </ion-chip>
        </div>
      </ion-row>
    </ion-grid>
    <div class="dices-results">
      <div class="results-title">Results:</div>
      <app-dices-results
        *ngIf="results"
        [results]="results"
      ></app-dices-results>
      <div class="no-results" *ngIf="!results">No results</div>
    </div>
  </div>
</app-base-modal>

import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import { concatMap, map, switchMap, take } from 'rxjs/operators';
import {
  CharDetailsAction,
  CharDetailsActionType,
} from './game-character-actions';
import { CharDetailsSingleActionRegistryResolver } from './character-single-action-registry-resolver';
import { CharacterService } from '../../api/services/character-service/character.service';
import { CharactersRegisterStore } from './game-characters-register.store';
import { SessionActionExternalPayload } from '../action-external-payload';
import { ConditionsStoreService } from '../../api/stores/conditions.store';
import { AbilitiesStoreService } from '../../api/stores/abilities.store';
import { GameDetailsRegisterManagerStore } from '../game-details/game-details-register-manager';
import { MonsterDefinitionsStoreService } from '../../api/stores/monster-definitions.store';
import { CreatureType } from 'src/be-models/interfaces/characters/creature-type.enum';
import {
  BaseCreature,
  CreaturePopulated,
} from 'src/be-models/interfaces/characters/base-creature';
import {
  Monster,
  MonsterPopulated,
  MonsterPopulatedWithDefinition,
} from 'src/be-models/interfaces/characters/monster';
import { CharactersUtils } from './game-characters-utils';
import { NPC } from 'src/be-models/interfaces/characters/npc';
import {
  BaseExternalManagerActions,
  BaseManagerActions,
  BaseRegistersManager,
} from '../base-registers-manager';
import { AppService } from '../../services/app.service';
import { TraitStoreService } from '../../api/stores/traits.store';
import { BackgroundStoreService } from '../../api/stores/backgrounds.store';

export class GameCharDetailsRegistersManagerActions extends BaseManagerActions<CharDetailsAction> {
  appendFetchedCharactersToRegistry$ = new Subject<BaseCreature[]>();

  setCurrentlySelectedCharacter$: Subject<CreaturePopulated> =
    new Subject<CreaturePopulated>();
  setCurrentlySelectedCharacterById$: Subject<string> = new Subject<string>();

  // appendCreatedNPCMonsterCharacterToRegistryAndSwitchToIt$: Subject<CreaturePopulated> =
  //   new Subject<CreaturePopulated>();
}

export class GameCharDetailsRegistersManagerExternalActions extends BaseExternalManagerActions<CharDetailsAction> {
  loadAllCharactersForGame$: Subject<
    { checkoutCharId?: string; includeNpcsMonsters?: boolean } | undefined
  > = new Subject<
    { checkoutCharId?: string; includeNpcsMonsters?: boolean } | undefined
  >();

  loadNpcsForHideouts$: Subject<string[]> = new Subject<string[]>();
}

@Injectable({
  providedIn: 'root',
})
export class CharactersRegisterManagerStore extends BaseRegistersManager<CharDetailsAction> {
  // subscriptions: Subscription = new Subscription();

  actions: GameCharDetailsRegistersManagerActions =
    new GameCharDetailsRegistersManagerActions();
  externalActions: GameCharDetailsRegistersManagerExternalActions =
    new GameCharDetailsRegistersManagerExternalActions();
  charDetailsActionRegistryResolver: CharDetailsSingleActionRegistryResolver;

  // TODO: change to normal subject and subscribe inside Inventory??
  // refreshViewTrigger$: Subject<void> = new Subject();
  charsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  currentlySelectedCharacter$: BehaviorSubject<CreaturePopulated>;
  constructor(
    private gameCharDetailsRegister: CharactersRegisterStore,
    private charactersService: CharacterService,
    private gameDetailsRegisterManager: GameDetailsRegisterManagerStore,
    private monsterDefsStore: MonsterDefinitionsStoreService,
    private abilitiesStoreService: AbilitiesStoreService,
    private conditionsStoreService: ConditionsStoreService,
    private traitsStoreService: TraitStoreService,
    private backgroundsStoreService: BackgroundStoreService,
    appService: AppService
  ) {
    super(appService);
    this.registerOnlineHandlers();
    this.registerExternalHandlers();
    this.currentlySelectedCharacter$ =
      this.gameCharDetailsRegister.currentlySelectedCharacter$;
    this.charDetailsActionRegistryResolver =
      new CharDetailsSingleActionRegistryResolver(
        traitsStoreService,
        backgroundsStoreService,
        conditionsStoreService,
        abilitiesStoreService,
        monsterDefsStore
      );
  }

  registerOnlineHandlers() {
    this.subscriptions.add(
      this.actions.appendFetchedCharactersToRegistry$
        .pipe(
          switchMap(async (creaturesFetched) => {
            this.charsLoading$.next(true);
            const test = await Promise.all(
              creaturesFetched.map(
                async (x) => await this.populateFetchedCharacters(x)
              )
            );
            return test;
            // of(
            // TODO: use this logic (with population) everywhere
            test;
            // );
          })
        )
        .subscribe(
          // (characters: CreaturePopulated[]) => {
          (characters) => {
            this.gameCharDetailsRegister.actions.addCharactersToRegister$.next(
              characters
            );
            this.gameCharDetailsRegister.charDetailsRegistryStateProjected$
              .pipe(take(1))
              .subscribe(() => {
                this.charsLoading$.next(false);
              });
          }
        )
    );

    this.subscriptions.add(
      merge(
        this.actions.resolveLocalActionsRequestWithCustomId$,
        this.actions.resolveLocalActionsRequest$.pipe(
          map((x) => ({ actions: x, id: undefined }))
        )
      ).subscribe(async ({ actions: localActions, id: customId }) => {
        if (localActions === undefined || localActions.length === 0) {
          return;
        }
        console.log('actions.charDetailsBatchOperations$', localActions);

        let charDetailsStateArray = JSON.parse(
          JSON.stringify(
            this.gameCharDetailsRegister.charDetailsRegistryStateProjected
          )
        );
        const timestamp = Date.now();
        const payload = {
          operationId: customId ?? 'actionId:' + crypto.randomUUID(),
          actions: localActions,
          timestamp,
          gameId: this.gameDetailsRegisterManager.getState()?.id,
        };
        this.queuedRequestedOperations.push(payload);
        // F.ex. for creating new char or any offline activity
        this.externalActions.requestActions$.next(payload);

        for (const action of localActions) {
          charDetailsStateArray =
            await this.charDetailsActionRegistryResolver.resolveCharDetailsSingleActionRegistry(
              action,
              charDetailsStateArray,
              timestamp
            );
        }
        this.gameCharDetailsRegister.updateProjectedCharDetailsRegistryState(
          charDetailsStateArray,
          payload.operationId
        );
      })
    );

    this.subscriptions.add(
      this.actions.setCurrentlySelectedCharacter$.subscribe((x) =>
        this.gameCharDetailsRegister.actions.setCurrentlySelectedCharacter$.next(
          x
        )
      )
    );

    this.subscriptions.add(
      this.actions.setCurrentlySelectedCharacterById$.subscribe((id) => {
        this.gameCharDetailsRegister.actions.setCurrentlySelectedCharacterById$.next(
          id
        );
      })
    );
  }

  registerExternalHandlers() {
    // TODO: a LOT of cleanup still here
    this.subscriptions.add(
      this.externalActions.loadAllCharactersForGame$
        .pipe(
          switchMap((data) => {
            const gameState =
              this.gameDetailsRegisterManager.loadedGameStaticInfo$$.value;

            this.charsLoading$.next(true);
            return combineLatest([
              this.charactersService
                .getCharactersForGame(gameState.id, data?.includeNpcsMonsters)
                .pipe(
                  map((y) => ({
                    baseCreatures: y,
                    selectedCharId: data?.checkoutCharId,
                  }))
                ),
              this.abilitiesStoreService.abilitiesPopulated$,
              this.conditionsStoreService.conditions$,
              this.backgroundsStoreService.backgroundsPopulated$,
              this.traitsStoreService.traitsPopulated$,
              this.monsterDefsStore.monsterDefinitionsPopulated$,
            ]);
          })
        )
        .subscribe(
          async ([
            { baseCreatures, selectedCharId },
            allAbilities,
            allConditions,
            allBackgrounds,
            allTraits,
            allMonsterDefinitions,
          ]) => {
            const preparedCreatures = baseCreatures.map((x) =>
              CharactersUtils.populateCreature(
                x,
                allAbilities,
                allConditions,
                allMonsterDefinitions,
                allBackgrounds,
                allTraits
              )
            );

            this.gameCharDetailsRegister.actions.addCharactersToRegister$.next(
              preparedCreatures
            );
            this.gameCharDetailsRegister.charDetailsRegistryStateProjected$
              .pipe(take(1))
              .subscribe((x) => {
                if (selectedCharId) {
                  this.actions.setCurrentlySelectedCharacter$.next(
                    x.find((y) => y.id === selectedCharId)
                  );
                }
                this.charsLoading$.next(false);
              });
          }
        )
    );

    this.subscriptions.add(
      this.externalActions.loadNpcsForHideouts$
        .pipe(
          switchMap((x) => {
            this.charsLoading$.next(true);

            return this.charactersService.getNPCsForHideouts(
              this.gameDetailsRegisterManager.getState().id,
              x
            );
          })
        )
        .subscribe(async (charactersDetailed) => {
          this.gameCharDetailsRegister.actions.addCharactersToRegister$.next(
            await Promise.all(
              charactersDetailed.map(
                async (x) => await this.populateFetchedCharacters(x)
              )
            )
          );
          this.gameCharDetailsRegister.charDetailsRegistryStateProjected$
            .pipe(take(1))
            .subscribe(() => {
              this.charsLoading$.next(false);
            });
        })
    );

    // this.subscriptions.add(
    //   this.actions.appendCreatedNPCMonsterCharacterToRegistryAndSwitchToIt$
    //     .subscribe
    //     // async (char) => {
    //     //   if (char.type === CreatureType.Monster) {
    //     //     char = await CharactersUtils.enhanceMonstersWithDefinitionData(
    //     //       char as MonsterPopulated,
    //     //       this.monsterDefsStore
    //     //     );
    //     //   }
    //     //   this.gameCharDetailsRegister.actions.addCharactersToRegister$.next([
    //     //     char,
    //     //   ]);
    //     //   this.gameCharDetailsRegister.charDetailsRegistryStateProjected$
    //     //     .pipe(take(1))
    //     //     .subscribe((x) => {
    //     //       if (char.id) {
    //     //         this.actions.setCurrentlySelectedCharacter$.next(
    //     //           x.find((y) => y.id === char.id)
    //     //         );
    //     //       }
    //     //       this.charsLoading$.next(false);
    //     //     });
    //     // }
    //     ()
    // );

    this.initializeReSynchroniseWithServer((payload) => {
      console.log('reSynchroniseProjectedRegistryWithServerRegistry$');
      // needed for situation when error happens with amountCapacityTaken when it's influenced by projected state
      // but needs to be rolled back - by then, no mechanism to recalculate as it is recalculated on arrival from BE
      const charDetailsServerState =
        this.gameCharDetailsRegister.charDetailsRegistryStateServer;

      this.gameCharDetailsRegister.updateProjectedCharDetailsRegistryState(
        JSON.parse(JSON.stringify(charDetailsServerState)),
        payload.operationId
      );

      this.propagateFailureWithTimestamp(payload);
      this.removeOperationFromQueuedList(payload);
      return new Promise((resolve) => resolve(true));
      // this.refreshViewTrigger$.next();
    });

    this.initializeResolveOthersActions((payload) =>
      Promise.all([
        this.charDetailsExternalActionsHandler(payload, true),
        this.charDetailsExternalActionsHandler(payload, false),
      ])
    );

    // this.subscriptions.add(
    //   this.externalActions.resolveOthersActions$
    //     .pipe(
    //       concatMap((payload) =>
    //         Promise.all([
    //           this.charDetailsExternalActionsHandler(payload, true),
    //           this.charDetailsExternalActionsHandler(payload, false),
    //         ])
    //       )
    //     )
    //     .subscribe()
    // );

    this.initializeResolveOwnActions(async (payload) => {
      await this.charDetailsExternalActionsHandler(payload, true);
      if (this.conditionForUpdateOnOwnOperationAfterFailure(payload)) {
        this.charDetailsExternalActionsHandler(payload, false);
      }
      await this.removeOperationFromQueuedList(payload);
      // return new Promise((resolve)=>resolve(true));
    });

    // this.subscriptions.add(
    //   this.externalActions.reSynchroniseWithServer$.subscribe((payload) => {
    //     console.log('reSynchroniseProjectedRegistryWithServerRegistry$');
    //     // needed for situation when error happens with amountCapacityTaken when it's influenced by projected state
    //     // but needs to be rolled back - by then, no mechanism to recalculate as it is recalculated on arrival from BE
    //     const charDetailsServerState =
    //       this.gameCharDetailsRegister.charDetailsRegistryStateServer;

    //     this.gameCharDetailsRegister.updateProjectedCharDetailsRegistryState(
    //       JSON.parse(JSON.stringify(charDetailsServerState)),
    //       payload.operationId
    //     );

    //     this.propagateFailureWithTimestamp(payload);
    //     this.removeOperationFromQueuedList(payload);
    //     // this.refreshViewTrigger$.next();
    //   })
    // );

    // this.subscriptions.add(
    //   this.externalActions.resolveOwnActions$.subscribe((payload) => {
    //     this.charDetailsExternalActionsHandler(payload, true);
    //     if (this.conditionForUpdateOnOwnOperationAfterFailure(payload)) {
    //       this.charDetailsExternalActionsHandler(payload, false);
    //     }
    //     this.removeOperationFromQueuedList(payload);
    //   })
    // );
  }

  private async charDetailsExternalActionsHandler(
    payload: SessionActionExternalPayload<CharDetailsAction>,
    isServerRegistry: boolean
  ) {
    // server registry
    let registryCharDetailsArray = isServerRegistry
      ? this.gameCharDetailsRegister.charDetailsRegistryStateServer
      : this.gameCharDetailsRegister.charDetailsRegistryStateProjected;
    for (const action of payload.actions) {
      console.log(
        'isServerRegistry: ' + isServerRegistry,
        'externalActionsHandler, actionType: ',
        action.actionType,
        'payload timestamp: ',
        payload.timestamp
      );

      if (
        registryCharDetailsArray.some((x) => x.id === action.payload.id) ||
        action.actionType === CharDetailsActionType.CharCreationFinalized
      ) {
        registryCharDetailsArray =
          await this.handleExistingCharDetailscenarioAction(
            action,
            registryCharDetailsArray,
            payload.timestamp
          );
        // if we dont have character in the registry, we dont need to fetch anyway
      } else if (
        [
          CharDetailsActionType.CharRemovalRequested,
          CharDetailsActionType.CharRemovalFinalized,
          CharDetailsActionType.CharCreationRequested,
          CharDetailsActionType.CharCreationFinalized,
        ].includes(action.actionType)
      ) {
        return;
      } else {
        console.error('this part shouldnt be hit and should be removed');

        // registryCharDetailsArray =
        //   await this.handleFetchingCharDetailscenarioAction(
        //     action,
        //     registryCharDetailsArray,
        //     payload.timestamp
        //   );
      }
    }

    this.concludeCharDetailsActionWithUpdatesForRegistry(
      isServerRegistry,
      registryCharDetailsArray,
      payload.operationId
    );
  }

  private async handleExistingCharDetailscenarioAction(
    action: CharDetailsAction,
    registryCharDetailsArray: CreaturePopulated[],
    timestamp: number
  ): Promise<CreaturePopulated[]> {
    return await this.charDetailsActionRegistryResolver.resolveCharDetailsSingleActionRegistry(
      action,
      JSON.parse(JSON.stringify(registryCharDetailsArray)),
      timestamp
    );
  }

  private async handleFetchingCharDetailscenarioAction(
    action: CharDetailsAction,
    registryCharDetailsArray: CreaturePopulated[],
    timestamp: number
  ): Promise<CreaturePopulated[]> {
    console.error('this method shouldnt be hit and should be removed');

    // To promise, but it is imperative that we keep sync nature of this method
    const missingCharacter = await this.charactersService
      .getCharacterById(action.payload.id)
      .toPromise();

    if (missingCharacter === undefined) {
      console.error('couldnt fetch the character: ', action.payload.id);
      return registryCharDetailsArray;
    }

    registryCharDetailsArray.push(missingCharacter);

    // if there were other operations on BE on that character in between, they take precedence
    if (missingCharacter.dateModified >= action.payload.dateModified) {
      return registryCharDetailsArray;
    }
    return this.charDetailsActionRegistryResolver.resolveCharDetailsSingleActionRegistry(
      action,
      JSON.parse(JSON.stringify(registryCharDetailsArray)),
      timestamp
    );
  }

  private concludeCharDetailsActionWithUpdatesForRegistry(
    isServerRegistry: boolean,
    registryCharDetailsArray: CreaturePopulated[],
    operationId: string
  ) {
    if (isServerRegistry) {
      this.gameCharDetailsRegister.updateServerCharDetailsRegistryState(
        registryCharDetailsArray,
        operationId
      );
    } else {
      this.gameCharDetailsRegister.updateProjectedCharDetailsRegistryState(
        registryCharDetailsArray,
        operationId
      );
    }
  }

  // seems unused, cleanup later
  private async populateFetchedCharacters(unpopulatedChar: BaseCreature) {
    if (CharactersUtils.isNPCCharacterUnpopulated(unpopulatedChar)) {
      return CharactersUtils.populateNpcMonsterCharacter<NPC>(
        unpopulatedChar,
        await this.abilitiesStoreService.getAbilitiesPopulated,
        await this.conditionsStoreService.getConditions
      );
    } else if (CharactersUtils.isPlayerCharacterUnpopulated(unpopulatedChar)) {
      console.error('NOT IMPLEMENTED');
    } else if (CharactersUtils.isMonsterCharacterUnpopulated(unpopulatedChar)) {
      return await CharactersUtils.enhanceMonstersWithDefinitionData(
        CharactersUtils.populateNpcMonsterCharacter<Monster>(
          unpopulatedChar,
          await this.abilitiesStoreService.getAbilitiesPopulated,
          await this.conditionsStoreService.getConditions
        ),
        await this.monsterDefsStore.getMonsterDefinitionsPopulated
      );
    }
  }
}

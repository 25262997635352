import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-table-menu-settings-popover',
  templateUrl: './table-menu-settings-popover.component.html',
  styleUrls: ['./table-menu-settings-popover.component.scss'],
})
export class TableMenuSettingsPopoverComponent implements OnInit {
  @Input() options: any[] = [];

  // TODO: currently not used
  constructor(private popoverController: PopoverController) {}

  ngOnInit() {}

  optionSelected(option) {
    this.popoverController.dismiss(option);
  }
}

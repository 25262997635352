import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import DiceRollRequestDTO from 'src/be-models/dtos/dice-roll.dto';
import { HttpService } from '../../http.service';

export interface SingleDiceRollRequestData {
  diceType: number;
  damount: number;
  drolls: number;
  modifier?: number;
}

export interface DicesThrowResults {
  diceType: number; // numberOfSides
  dicesThrows: DicesThrow[];
}

export interface DicesThrow {
  diceResults: SingleDiceResult[];
  throwId: number;
}

export interface SingleDiceResult {
  diceId: number;
  value: number;
}

@Injectable({
  providedIn: 'root',
})
export class DiceService {
  constructor(private http: HttpService) {}

  rollDices(body: DiceRollRequestDTO): Observable<DicesThrowResults[]> {
    return this.http.post<DicesThrowResults[]>('/dice/roll', body);
  }
}

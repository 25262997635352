<div class="main">
  <div class="char-title">
    {{gameFeedRecord.characterName}}:
  </div>
  <div class="main-results" *ngIf="gameFeedRecord.roll">
    <div class="dice-throw-results" *ngFor="let diceThrowResult of gameFeedRecord.roll.dicesThrowsResults">
      <!-- {{diceThrowResult | json}} -->
      <div class="roll-title">{{'Roll ('+ diceThrowResult.dicesThrows[0].diceResults.length +'D' + diceThrowResult.diceType + (diceThrowResult.modifier ? ' + ' + diceThrowResult.modifier : '') + '):'}}</div>
      <div *ngFor="let diceSingleThrow of diceThrowResult.dicesThrows" class="single-throw">
        <div class="pure-results">
          ({{sortDices(diceSingleThrow.diceResults).join(', ')}})
        </div>
        <div class="grouped-table" *ngIf="diceThrowResult.diceType !== 20">
          <table>
            <th *ngFor="let key of sumUpDiceResults(diceSingleThrow.diceResults, diceThrowResult.diceType).keys()">
              {{key}}
            </th>
            <tr>
              <td *ngFor="let value of sumUpDiceResults(diceSingleThrow.diceResults, diceThrowResult.diceType).values()">
                {{value}}
              </td>
            </tr>
          </table>
        </div>
      </div>

    </div>
  </div>
  <div class="message" *ngIf="gameFeedRecord.message">
    {{gameFeedRecord.message}}
  </div>

</div>
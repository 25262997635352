import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateBaseCreatureRequest } from 'src/be-models/dtos/create-character-request';
import {
  BaseCreature,
  CreaturePopulated,
} from 'src/be-models/interfaces/characters/base-creature';
import {
  Character,
  CharacterPopulated,
} from 'src/be-models/interfaces/characters/character';
import { NPC } from 'src/be-models/interfaces/characters/npc';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root',
})
export class CharacterService {
  constructor(private http: HttpService) {}

  getCharacterById(characterId: string): Observable<CreaturePopulated> {
    return this.http.get('/character/' + characterId);
  }

  getCharactersForGame(
    gameId: string,
    includeNpcsMonsters: boolean
  ): Observable<BaseCreature[]> {
    return this.http.get(
      '/character/game/' +
        gameId +
        (includeNpcsMonsters ? '?includeNpcsMonsters=true' : '')
    );
  }

  getNPCsForHideouts(gameId: string, hideoutIds: string[]): Observable<NPC[]> {
    return this.http.get(
      '/character/game/' +
        gameId +
        '/byHideouts' +
        (hideoutIds.length > 0
          ? `?${hideoutIds.reduce(
              (prev, curr) => prev + 'hideoutIds=' + curr + '&',
              ''
            )}`
          : '')
    );
  }

  // createCharacters<T = Character>(
  //   createCharacterPayload: CreateBaseCreatureRequest<T>
  // ): Observable<{ operationId: string }> {
  //   return this.http.post('/character/create', createCharacterPayload);
  // }

  assignCharacterToSlot(
    characterId: string,
    slotId: string,
    gameId: string
  ): Observable<any> {
    return this.http.post('/character/assignCharacterToSlot', {
      characterId,
      slotId,
      gameId,
    });
  }

  // updateCharacter(
  //   characterId: string,
  //   updateCharacterPayload: Partial<CharacterPopulated>
  // ): Observable<any> {
  //   return this.http.post('/character/update/' + characterId, {
  //     payload: updateCharacterPayload,
  //   });
  // }

  deleteCharacter(characterId: string): Observable<any> {
    return this.http.delete('/character', [characterId]);
  }
}

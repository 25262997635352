import Ability from "../abilities/ability.interface";
import { Modify } from "../utils";

export default interface Background {
  id: string;
  name: string;
  tier: number;
  abilities: string[];
  description: string;
}

export interface BackgroundPopulated
  extends Modify<Background, { abilities: Ability[] }> {}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, switchMap, take } from 'rxjs';
import { GamePopulatedRegistry } from 'src/be-models/interfaces/games/game';
import { SessionActionExternalPayload } from '../action-external-payload';
import { GameDetailsSingleActionRegistryResolver } from './game-details-single-action-registry-resolver';
import { GameDetailsAction } from './game-details.actions';
import {
  BaseExternalManagerActions,
  BaseManagerActions,
  BaseRegistersManager,
} from '../base-registers-manager';
import { AppService } from '../../services/app.service';
import { GameDetailsSnapshotContent } from 'src/be-models/interfaces/snapshot/game-details-snapshot.interface';
import { GamesService } from '../../api/services/games-service/games.service';

export class GameDetailsRegistersManagerActions extends BaseManagerActions<GameDetailsAction> {
  setCurrentlySelectedGame$: Subject<{
    staticData: GamePopulatedRegistry;
    stateData: GameDetailsSnapshotContent;
  }> = new Subject<{
    staticData: GamePopulatedRegistry;
    stateData: GameDetailsSnapshotContent;
  }>();
}

export class GameDetailsRegistersManagerExternalActions extends BaseExternalManagerActions<GameDetailsAction> {
  loadGameDetailsForGame$: Subject<void> = new Subject<void>();
}

// export interface GamePopulatedRegistry extends GamePopulated {
//   role: GameRole;
//   // slotDetails: Partial<PlayerSlotPopulated>;
// }

@Injectable({
  providedIn: 'root',
})
export class GameDetailsRegisterManagerStore extends BaseRegistersManager<GameDetailsAction> {
  protected subscriptions: Subscription = new Subscription();

  actions: GameDetailsRegistersManagerActions =
    new GameDetailsRegistersManagerActions();
  externalActions: GameDetailsRegistersManagerExternalActions =
    new GameDetailsRegistersManagerExternalActions();
  gameDetailsActionRegistryResolver: GameDetailsSingleActionRegistryResolver;

  activeGameProjectedState$$: BehaviorSubject<GameDetailsSnapshotContent> =
    new BehaviorSubject<GameDetailsSnapshotContent>(undefined);
  activeGameServerState$$: BehaviorSubject<GameDetailsSnapshotContent> =
    new BehaviorSubject<GameDetailsSnapshotContent>(undefined);
  loadedGameStaticInfo$$: BehaviorSubject<GamePopulatedRegistry> =
    new BehaviorSubject<GamePopulatedRegistry>(undefined);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private gamesService: GamesService, appService: AppService) {
    super(appService);
    this.gameDetailsActionRegistryResolver =
      new GameDetailsSingleActionRegistryResolver();
    this.registerOnlineHandlers();
    this.registerExternalHandlers();
  }

  getState() {
    return this.activeGameProjectedState$$.getValue();
  }

  registerOnlineHandlers() {
    this.subscriptions.add(
      this.actions.resolveLocalActionsRequest$.subscribe((localActions) => {
        if (localActions === undefined || localActions.length === 0) {
          return;
        }
        console.log('actions.gameDetailsBatchOperations$', localActions);
        let gameDetailsProjectedState = JSON.parse(
          JSON.stringify(this.activeGameProjectedState$$.value)
        );

        const timestamp = Date.now();
        // F.ex. for creating new game or any offline activity
        const payload = {
          operationId: 'actionId:' + crypto.randomUUID(),
          actions: localActions,
          timestamp,
          gameId: this.getState()?.id,
        };
        this.externalActions.requestActions$.next(payload);
        this.queuedRequestedOperations.push(payload);
        // }

        for (const action of localActions) {
          // special condition for day passed - as it's treated as "internal" on the backend,
          // we want to avoid updating date twice, so dont do it locally
          // if (action.actionType !== GameDetailsActionType.DayPassed) {
          gameDetailsProjectedState =
            this.gameDetailsActionRegistryResolver.resolveGameDetailsSingleActionRegistry(
              action,
              gameDetailsProjectedState,
              timestamp
            );
          // }
        }
        this.updateProjectedGameDetailsRegistryState(gameDetailsProjectedState);
      })
    );

    this.subscriptions.add(
      this.actions.setCurrentlySelectedGame$.subscribe(
        ({ staticData, stateData }) => {
          console.log('setCurrentlySelectedGame');
          this.loadedGameStaticInfo$$.next(staticData);
          this.updateServerGameDetailsRegistryState(
            stateData ? JSON.parse(JSON.stringify(stateData)) : undefined
          );
          this.updateProjectedGameDetailsRegistryState(
            stateData ? JSON.parse(JSON.stringify(stateData)) : undefined
          );
        }
      )
    );
  }

  registerExternalHandlers() {
    this.initializeReSynchroniseWithServer((payload) => {
      console.log('reSynchroniseProjectedRegistryWithServerRegistry$');
      const gameDetailsServerState = JSON.parse(
        JSON.stringify(this.activeGameServerState$$.value)
      );

      this.updateProjectedGameDetailsRegistryState(gameDetailsServerState);

      this.propagateFailureWithTimestamp(payload);
      this.removeOperationFromQueuedList(payload);
      return new Promise((resolve) => resolve(true));

      // this.refreshViewTrigger$.next();
    });

    this.initializeResolveOthersActions(async (payload) => {
      // update server registry
      this.gameDetailsExternalActionsHandler(payload, true);
      // update projection registry
      this.gameDetailsExternalActionsHandler(payload, false);
    });

    this.initializeResolveOwnActions((payload) => {
      this.gameDetailsExternalActionsHandler(payload, true);
      if (this.conditionForUpdateOnOwnOperationAfterFailure(payload)) {
        this.gameDetailsExternalActionsHandler(payload, false);
      }
      this.removeOperationFromQueuedList(payload);
      return new Promise((resolve) => resolve(true));
    });

    // this.subscriptions.add(
    //   this.externalActions.resolveOthersActions$.subscribe(async (payload) => {
    //     // update server registry
    //     this.gameDetailsExternalActionsHandler(payload, true);
    //     // update projection registry
    //     this.gameDetailsExternalActionsHandler(payload, false);
    //   })
    // );

    // this.subscriptions.add(
    //   this.externalActions.reSynchroniseWithServer$.subscribe((payload) => {
    //     console.log('reSynchroniseProjectedRegistryWithServerRegistry$');
    //     const gameDetailsServerState = JSON.parse(
    //       JSON.stringify(this.activeGameServerState$$.value)
    //     );

    //     this.updateProjectedGameDetailsRegistryState(gameDetailsServerState);

    //     this.propagateFailureWithTimestamp(payload);
    //     this.removeOperationFromQueuedList(payload);

    //     // this.refreshViewTrigger$.next();
    //   })
    // );

    // this.subscriptions.add(
    //   this.externalActions.resolveOwnActions$.subscribe((payload) => {
    //     this.gameDetailsExternalActionsHandler(payload, true);
    //     if (this.conditionForUpdateOnOwnOperationAfterFailure(payload)) {
    //       this.gameDetailsExternalActionsHandler(payload, false);
    //     }
    //     this.removeOperationFromQueuedList(payload);
    //   })
    // );

    this.subscriptions.add(
      this.externalActions.loadGameDetailsForGame$
        .pipe(
          switchMap(() => {
            // const gameState = this.getState();
            this.loading$.next(true);

            if (this.loadedGameStaticInfo$$.value?.id === undefined) {
              throw new Error('gameInfo is undefined');
            }
            return this.gamesService.getGameInGameDetails(
              this.loadedGameStaticInfo$$.value?.id
            );
          })
        )
        .subscribe((inGameDetails) => {
          this.actions.setCurrentlySelectedGame$.next({
            staticData: this.loadedGameStaticInfo$$.value,
            stateData: inGameDetails,
          });
          this.activeGameProjectedState$$.pipe(take(1)).subscribe((x) => {
            this.loading$.next(false);
          });
        })
    );
  }

  private async gameDetailsExternalActionsHandler(
    payload: SessionActionExternalPayload<GameDetailsAction>,
    isServerRegistry: boolean
  ) {
    // server registry
    let registryGameDetails = isServerRegistry
      ? JSON.parse(JSON.stringify(this.activeGameServerState$$.value))
      : JSON.parse(JSON.stringify(this.activeGameProjectedState$$.value));
    for (const action of payload.actions) {
      console.log(
        'isServerRegistry: ' + isServerRegistry,
        'externalActionsHandler, actionType: ',
        action.actionType,
        'payload timestamp: ',
        payload.timestamp
      );
      registryGameDetails =
        this.gameDetailsActionRegistryResolver.resolveGameDetailsSingleActionRegistry(
          action,
          registryGameDetails,
          payload.timestamp
        );
    }

    if (isServerRegistry) {
      this.updateServerGameDetailsRegistryState(registryGameDetails);
    } else {
      this.updateProjectedGameDetailsRegistryState(registryGameDetails);
    }
  }

  updateProjectedGameDetailsRegistryState(
    newState: GameDetailsSnapshotContent
  ) {
    this.activeGameProjectedState$$.next(newState);
    console.log(
      'GameDetails: stateUpdatedProjected',
      this.activeGameProjectedState$$.getValue()
    );
  }

  updateServerGameDetailsRegistryState(newState: GameDetailsSnapshotContent) {
    this.activeGameServerState$$.next(newState);
    console.log(
      'GameDetails: stateUpdatedServer',
      this.activeGameServerState$$.getValue()
    );
  }
}

import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@capacitor/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import HttpUtils from './services/http-utils';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {
    console.log(environment);
  }

  get<T = any>(url: string): Observable<T> {
    return this.http.get<T>(HttpUtils.getApiUrl() + url);
  }

  put<T = any>(url: string, body: any): Observable<T> {
    return this.http.put<T>(HttpUtils.getApiUrl() + url, body);
  }

  post<T = any>(url: string, body: any): Observable<T> {
    return this.http.post<T>(HttpUtils.getApiUrl() + url, body);
  }

  delete<T = any>(
    url: string,
    ids: Array<string>,
    queryParam = 'ids'
  ): Observable<T> {
    return this.http.delete<T>(
      HttpUtils.getApiUrl() + url + '?' + queryParam + '=' + ids
    );
  }
}

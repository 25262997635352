import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Type,
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AutoPositioningInfoPopoverLogic } from './auto-positioning-info-popover-logic';
import { AutoPositioningInfoPopoverComponent } from './auto-positioning-info-popover.component';

@Directive({
  selector: '[appAutoPositioningInfoPopover]',
})
export class AutoPositioningInfoPopoverDirective {
  @Input() simpleText: string = undefined;
  @Input() component: Type<unknown> = AutoPositioningInfoPopoverComponent;
  @Input() componentProps;

  autoPositioningInfoPopoverLogic: AutoPositioningInfoPopoverLogic;

  constructor(popoverController: PopoverController) {
    this.autoPositioningInfoPopoverLogic = new AutoPositioningInfoPopoverLogic(
      popoverController
    );
  }

  ngOnInit() {}
  @HostListener('click', ['$event'])
  async onClick(event: PointerEvent) {
    event.stopPropagation();
    if (this.simpleText) {
      this.autoPositioningInfoPopoverLogic.executeSimpleTextPopoverCreation(
        this.simpleText,
        event,
        this.component
      );
    }
  }
}

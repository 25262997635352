import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DiceRollerComponent } from 'src/app/shared/modals/dice-roller-modal/dice-roller.component';
import { ModalAnimationsCreatorService } from 'src/app/shared/modals/modal-animations';
import { AdminService } from '../api/services/admin-service/admin.service';
import { GameDetailsRegisterManagerStore } from '../game/game-details/game-details-register-manager';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent implements OnInit {
  loading: boolean;
  constructor(
    private adminService: AdminService,
    public gameDetails: GameDetailsRegisterManagerStore,
    private modalAnimationsCreatorService: ModalAnimationsCreatorService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  resetGameRegistries() {
    console.log('bum');
    // this.adminService
    //   .resetGameRegistries(this.gameDetails.getState().id)
    //   .subscribe((x) => {
    //     console.log('resetGamesRegistiersResponse', x);
    //   });
  }

  changeFont(event) {
    console.log(event.detail.value);
    // font-family: "Painting_With_Chocolate";
    // --ion-font-family: "Painting_With_Chocolate";
    // --table-content-font-family: "Painting_With_Chocolate";

    const fontName = event.detail.value;

    (
      document.querySelector('body > app-root > ion-app') as any
    ).style.cssText = `font-family: "${fontName}"; --ion-font-family: "${fontName}"; --table-content-font-family: "${fontName}"; --table-header-font-family: "${fontName}"`;

    // document.documentElement.style.cssText =
    //   '--ion-font-family: ' + event.detail.value;
    // document.documentElement.style.cssText =
    //   'font-family: ' + event.detail.value;
  }

  public async openDiceRoller() {
    if (
      (await this.modalCtrl.getTop())?.component !== DiceRollerComponent &&
      !this.loading
    ) {
      this.loading = true;
      const modal = await this.modalCtrl.create({
        component: DiceRollerComponent,
        enterAnimation:
          this.modalAnimationsCreatorService.createEnterAnimation(),
        leaveAnimation:
          this.modalAnimationsCreatorService.createLeaveAnimation(),
        cssClass: 'dice-roller',
      });
      modal.present().then((x) => (this.loading = false));
    }
  }
}

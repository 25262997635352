import Ability, {
  AbilityPopulated,
} from 'src/be-models/interfaces/abilities/ability.interface';
import StringUtils from './string.utils';

export class SkillsUtils {
  static resolveStatsBonus(
    skillName: string,
    abilities: (Ability | AbilityPopulated)[]
  ): number {
    try {
      const result = abilities.reduce((prev, curr) => {
        return (
          prev +
          (isNaN(Number(curr.bonus?.[StringUtils.camelize(skillName)]))
            ? 0
            : Number(curr.bonus?.[StringUtils.camelize(skillName)]))
        );
      }, 0);

      return result;
    } catch (error) {
      console.error(error, skillName, abilities);
    }
  }
}
